import { gql } from "apollo-boost";
import { settings } from "cluster";

export const GET_TRANSLATIONS = gql`
  query {
    Keyword {
      getAll {
        en {
          key
          value
          createdAt
          updatedAt
        }
      }
    }
  }
`;

const GetWatchSubscribeDataArguments = `{
    $page: Int,
    $limit: Int,
    $search_by: String,
    $type: String,
    $order_by_field: String,
    $order_by: String,
    $csv_download_link: Boolean,
    $send_type_list: Boolean,
}`;

export const GET_SUBSCRIBED_EMAIL_LIST = gql`
  query ($settings: GetWatchSubscribeDataArguments) {
    Admin {
      getWatchSubscribeData(settings: $settings) {
        subscribed_emails {
          email
          createdAt
          type
          comment
          verified
          lead_magnet_email_send_at
        }
        total_count
        type_list
        csv_url
        error
        message
      }
    }
  }
`;

export const GET_EMAIL_BLACKLIST_LIST = gql`
  query AdminGetMany(
    $page: Int
    $limit: Int
    $filter_by_email: String
    $sort_by: String
    $sort_by_creation_date: Boolean
  ) {
    EmailBlackList {
      adminGetMany(
        page: $page
        limit: $limit
        filter_by_email: $filter_by_email
        sort_by: $sort_by
        sort_by_creation_date: $sort_by_creation_date
      ) {
        total
        email_black_list {
          id
          email
          updatedAt
          createdAt
        }
        error
        message
      }
    }
  }
`;

export const GET_USERS = gql`
  query users($settings: GetManyInputType) {
    User {
      getMany(settings: $settings) {
        total
        csvURL
        googleDrive
        includePayments
        users {
          isPerformer
          id
          firstName
          lastName
          email
          phone
          createdAt
          lastLogin
          verified
          verifyCode
          active
          shouldBeDeletedAt
          ip
          countryCode
          loginSource
          twofa {
            type
            status
          }
          pricingPackage
          pricingPackageInterval
          detectedCountry
          config {
            additional_scans_count
          }
          hexometer_pricing_package
          hexometer_pricing_package_interval
          hexometer_pricing_package_count
          hexomatic_pricing_package
          hexomatic_pricing_package_interval
          hexomatic_pricing_package_count

          hexospark_pricing_package
          hexospark_pricing_package_interval
          hexospark_pricing_package_count

          hexofy_pricing_package
          hexofy_pricing_package_interval
          hexofy_pricing_package_count

          premium_credits_pricing_package
          premium_credits_pricing_package_interval
          premium_credits_stripe_subscription_id
          left_premium_credits
          left_automation_credit
          left_one_time_premium_credits

          netAppsumoCodes
          netAppsumoCodesRefund
          netSpentPaypal
          netRefundedPaypal
          netSpentStripe
          netRefundedStripe
          netRefund
          netPaid
          stripeBalance

          impact_data {
            click_id
            media_partner_id
          }
          referer {
            document_referrer
            header_referer
          }
          hexometer_properties_count
          inviter_id
          layer
          db_location
          watch_payment_type
          hm_payment_type
          hc_payment_type
          pc_payment_type
          hs_payment_type
          hf_payment_type
        }
      }
    }
  }
`;

export const GET_HEXOMATIC_PRICING_PACK_NAMES = gql`
  query GET_HEXOMATIC_PRICING_PACK_NAMES($hideHex: Boolean) {
    User {
      getHexomaticPricingPackageNames(hideHex: $hideHex) {
        name
      }
    }
  }
`;

export const GET_HEXOWATCH_PRICING_PACK_NAMES = gql`
  query GET_HEXOWATCH_PRICING_PACK_NAMES($hideHex: Boolean) {
    User {
      getHexowatchPricingPackageNames(hideHex: $hideHex) {
        name
      }
    }
  }
`;

export const GET_HEXOMETER_PRICING_PACK_NAMES = gql`
  query GET_HEXOMETER_PRICING_PACK_NAMES($hideHex: Boolean) {
    User {
      getHexometerPricingPackageNames(hideHex: $hideHex) {
        name
      }
    }
  }
`;

export const GET_USER_PREMIUM_CREDIT_PRICING_PACKAGE = gql`
  query GET_USER_PREMIUM_CREDIT_PRICING_PACKAGE($hideHex: Boolean) {
    User {
      getUserPremiumCreditPricingPackage(hideHex: $hideHex) {
        name
      }
    }
  }
`;

export const SCAN_REQUEST_LOG = gql`
  query scanRequestLog(
    $input: FilterArgsType
    $tool: String
    $source: String
    $csvDownload: Boolean
    $countryCode: String
    $orderBy: String
    $orderByASC: Boolean
    $driveUpload: Boolean
  ) {
    ScanRequestLog {
      getMany(
        input: $input
        tool: $tool
        source: $source
        csvDownload: $csvDownload
        countryCode: $countryCode
        orderBy: $orderBy
        orderByASC: $orderByASC
        driveUpload: $driveUpload
      ) {
        total
        filteredLength
        csvURL
        googleDrive
        requestLogs {
          user {
            email
          }
          input
          scanTool
          time
          ip
          countryCode
          source
        }
      }
    }
  }
`;

export const SCAN_SUBSCRIPTION = gql`
  query scanSubscriptions($pageNumber: Int, $filterData: String) {
    ScanSubscription {
      getMany(pageNumber: $pageNumber, filterData: $filterData) {
        total
        subscriptions {
          id
          user {
            firstName
            email
          }
          tool
          url
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const DAILY_METRICS = gql`
  query dailyMetrics($hideHexScans: Boolean) {
    Dashboard {
      metrics(hideHexScans: $hideHexScans) {
        usersCountToday
        scanCountToday
        subscriptionsToday
        totalUsersCount
        totalScanRequests
        totalActivatedUsers
      }
    }
  }
`;

export const GET_ACTIVE_USERS_TIME_SERIES = gql`
  query {
    Admin {
      getActiveUsersTimeSeries {
        time_series {
          count
          time
        }
        error
        message
      }
    }
  }
`;

export const GET_NOT_ACTIVE_USERS_TIME_SERIES = gql`
  query {
    Admin {
      getNotActiveUsersTimeSeries {
        time_series {
          count
          time
        }
        error
        message
      }
    }
  }
`;

export const USERS_REGISTRATION_CHART = gql`
  query USERS_REGISTRATION_CHART($frequency: String, $from: String, $to: String) {
    User {
      registrationChart(frequency: $frequency, from: $from, to: $to) {
        result {
          time
          hexometer
          hexowatch
          hexomatic
          hexospark
          hexofy
        }
      }
    }
  }
`;

export const USERS_BLOCKAGE_CHART = gql`
  query {
    User {
      blockageChart {
        count
        time
      }
    }
  }
`;

export const USERS_DELETION_CHART = gql`
  query {
    User {
      deletionChart {
        count
        time
      }
    }
  }
`;

export const USERS_REGISTRATION_PIECHART = gql`
  query usesRegPiechart($from: String, $to: String) {
    User {
      registrationPiechart(from: $from, to: $to) {
        field
        count
      }
    }
  }
`;

export const USERS_WITH_PROPERTIES_PIECHART = gql`
  query usersWithPropertiesPiechart($from: String, $to: String, $hideHex: Boolean) {
    User {
      propertiesPiechart(from: $from, to: $to, hideHex: $hideHex) {
        field
        count
      }
    }
  }
`;

export const USERS_BY_COUNTRY_PIECHART = gql`
  query countriesPiechart($from: String, $to: String, $hideHex: Boolean) {
    User {
      countriesPiechart(from: $from, to: $to, hideHex: $hideHex) {
        field
        count
      }
    }
  }
`;

export const SCANREQUESTLOG_CHART = gql`
  query scanRequestLog($hideHexScans: Boolean, $webOnly: Boolean) {
    ScanRequestLog {
      scanRequestLogChart(interval: "day", hideHexScans: $hideHexScans, webOnly: $webOnly) {
        count
        time
      }
    }
  }
`;

export const SCANREQUESTLOG_PIECHART = gql`
  query scanRequestLog(
    $groupedField: String
    $hideHexScans: Boolean
    $showAll: Boolean
    $from: String
    $to: String
  ) {
    ScanRequestLog {
      scanRequestLogPieChart(
        groupedField: $groupedField
        hideHexScans: $hideHexScans
        showAll: $showAll
        from: $from
        to: $to
      ) {
        count
        field
      }
    }
  }
`;

export const HEADER_DATA = gql`
  query {
    HeaderData {
      getMany {
        id
        path
        title
        metaTags {
          name
          content
        }
      }
    }
  }
`;
export const GET_PROPERTIES = gql`
  query properties(
    $input: FilterArgsType
    # $pricingPackage: String
    $paused: Boolean
    $orderBy: String
    $orderByASC: Boolean
    $scheduledToDelete: Boolean
    # $tasksCountMin: Int,
    # $tasksCountMax: Int,
    # $taskType: String,
    $csvDownload: Boolean
    $countryCode: String
    $shared: Boolean
    $withoutPromo: Boolean
    $driveUpload: Boolean
    $techStack: String
    $issue_group: String
    $issue_count_min: Int
    $issue_count_max: Int
  ) {
    Property {
      adminGetMany(
        input: $input
        # pricingPackage: $pricingPackage
        paused: $paused
        orderBy: $orderBy
        orderByASC: $orderByASC
        scheduledToDelete: $scheduledToDelete
        # tasksCountMin: $tasksCountMin,
        # tasksCountMax: $tasksCountMax,
        # taskType: $taskType,
        csvDownload: $csvDownload
        countryCode: $countryCode
        shared: $shared
        withoutPromo: $withoutPromo
        driveUpload: $driveUpload
        techStack: $techStack
        issue_group: $issue_group
        issue_count_min: $issue_count_min
        issue_count_max: $issue_count_max
      ) {
        total
        csvURL
        googleDrive
        properties {
          id
          name
          address
          propertyType
          hostname
          paused
          createdAt
          updatedAt
          userEmail
          pricingPackage
          promoCode
          ip
          countryCode
          scheduledToDeleteAt
          sharedUsersIds
          techStack
        }
      }
    }
  }
`;

export const PRICING_PIECHART = gql`
  query pricingPiechart($hidePropertiesFromHex: Boolean, $from: String, $to: String) {
    Property {
      pricingPackagePiechart(hidePropertiesFromHex: $hidePropertiesFromHex, from: $from, to: $to) {
        field
        count
      }
    }
  }
`;

export const HEXOMATIC_PRICING_PIECHART = gql`
  query getHexomaticPricingPiechart($hideHex: Boolean, $from: String, $to: String) {
    HexomaticUser {
      getHexomaticPricingPiechart(hideHex: $hideHex, from: $from, to: $to) {
        field
        count
      }
    }
  }
`;

export const UPGRADED_PROPERTIES_PIECHART = gql`
  query upgradesPiechart($hideHex: Boolean, $from: String, $to: String) {
    Property {
      upgradesPiechart(hideHex: $hideHex, from: $from, to: $to) {
        field
        count
      }
    }
  }
`;

export const PROPERTY_SUBSCRIPTIONS_CHART = gql`
  query propertySubscriptions($hidePropertiesFromHex: Boolean) {
    Property {
      subscriptionsChart(hidePropertiesFromHex: $hidePropertiesFromHex) {
        count
        time
      }
    }
  }
`;

export const GET_USER_LOGS = gql`
  query userLogsQuery(
    $input: FilterArgsType
    $logFrom: String
    $logTo: String
    $action: String
    $orderBy: String
    $orderByASC: Boolean
    $csvDownload: Boolean
    $driveUpload: Boolean
  ) {
    UserLog {
      adminGetMany(
        input: $input
        logFrom: $logFrom
        logTo: $logTo
        action: $action
        orderBy: $orderBy
        orderByASC: $orderByASC
        csvDownload: $csvDownload
        driveUpload: $driveUpload
      ) {
        total
        csvURL
        googleDrive
        userLogs {
          id
          user {
            firstName
            lastName
            email
            createdAt
          }
          action
          remoteAddress
          createdAt
          countryCode
        }
      }
    }
  }
`;

export const USER_LOGS_CHART = gql`
  query uniqueLogsChart($hideHexLogins: Boolean, $from: String, $to: String, $frequency: String) {
    UserLog {
      adminUniqueLogsChart(hideHexLogins: $hideHexLogins, from: $from, to: $to, frequency: $frequency) {
        count
        time
      }
    }
  }
`;

export const GET_TASKS = gql`
  query tasks($input: FilterArgsType, $priority: String, $resolved: String) {
    Task {
      adminGetMany(input: $input, priority: $priority, resolved: $resolved) {
        total
        tasks {
          id
          title
          content
          targetValue
          linkHash
          resolved
          date
          priority
          taskType
          scanTool
          monitoringLogReference
          createdAt
          user {
            email
          }
          property {
            address
          }
        }
      }
    }
  }
`;

export const GET_PROMOCODES = gql`
  query promocodes(
    $input: FilterArgsType
    $activated: Boolean
    $orderBy: String
    $orderByASC: Boolean
    $activatedFrom: String
    $activatedTo: String
  ) {
    PromoCode {
      adminGetMany(
        input: $input
        activated: $activated
        orderByASC: $orderByASC
        orderBy: $orderBy
        activatedFrom: $activatedFrom
        activatedTo: $activatedTo
      ) {
        total
        promocodes {
          id
          user {
            firstName
            lastName
            email
          }
          code
          targetPackage
          targetExtra
          targetValue
          activated
          activatedAt
          createdAt
          expireAt
          usedTimes
          canceled
        }
      }
    }
  }
`;

export const TASKS_PIECHART = gql`
  query adminTasksPiechart($from: String, $to: String, $hideHex: Boolean) {
    Task {
      adminTasksPiechart(from: $from, to: $to, hideHex: $hideHex) {
        field
        count
      }
    }
  }
`;

export const PROMOCODE_ACTIVATION_CHART = gql`
  query {
    PromoCode {
      activationsChart {
        time
        count
      }
    }
  }
`;

export const PROPERTIES_DELETION_CHART = gql`
  query {
    Property {
      deletionChart {
        count
        time
      }
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query notifications(
    $input: FilterArgsType
    $channel: String
    $type: String
    $orderBy: String
    $orderByASC: Boolean
    $csvDownload: Boolean
    $driveUpload: Boolean
  ) {
    Notification {
      adminGetMany(
        input: $input
        channel: $channel
        type: $type
        orderBy: $orderBy
        orderByASC: $orderByASC
        csvDownload: $csvDownload
        driveUpload: $driveUpload
      ) {
        total
        csvURL
        googleDrive
        notifications {
          id
          channel
          type
          createdAt
          user {
            email
            firstName
            lastName
          }
          property {
            hostname
          }
        }
      }
    }
  }
`;

export const NOTIFICATIONS_CHART = gql`
  query notificationsChart($channel: String) {
    Notification {
      notificationsChart(channel: $channel) {
        time
        count
      }
    }
  }
`;

export const NOTIFICATIONS_PIECHART = gql`
  query notificationsPieChart($from: String, $to: String, $hideHex: Boolean) {
    Notification {
      notificationsPieChart(from: $from, to: $to, hideHex: $hideHex) {
        field
        count
      }
    }
  }
`;

export const GET_CHANNELS = gql`
  query channels(
    $input: FilterArgsType
    $name: String
    $orderBy: String
    $orderByASC: Boolean
    $csvDownload: Boolean
    $driveUpload: Boolean
  ) {
    Channel {
      adminGetMany(
        input: $input
        name: $name
        orderBy: $orderBy
        orderByASC: $orderByASC
        csvDownload: $csvDownload
        driveUpload: $driveUpload
      ) {
        total
        csvURL
        googleDrive
        channels {
          id
          name
          createdAt
          user {
            email
            firstName
            lastName
          }
          property {
            hostname
          }
        }
      }
    }
  }
`;

export const CHANNELS_CHART = gql`
  query channelsChart($name: String) {
    Channel {
      channelsChart(name: $name) {
        time
        count
      }
    }
  }
`;

export const CHANNELS_PIECHART = gql`
  query channelsPieChart($from: String, $to: String, $hideHex: Boolean) {
    Channel {
      channelsPieChart(from: $from, to: $to, hideHex: $hideHex) {
        field
        count
      }
    }
  }
`;

export const GET_ADMIN = gql`
  query {
    Admin {
      adminGet {
        viewer
        username
        settings {
          csvDownload
        }
      }
    }
  }
`;

export const GET_QUESTIONNAIRES = gql`
  query questionnaires(
    $input: FilterArgsType
    $answer1: String
    $answer2: String
    $orderBy: String
    $orderByASC: Boolean
    $csvDownload: Boolean
    $driveUpload: Boolean
    $type: String
  ) {
    Questionnaire {
      adminGetMany(
        input: $input
        answer1: $answer1
        answer2: $answer2
        orderBy: $orderBy
        orderByASC: $orderByASC
        csvDownload: $csvDownload
        driveUpload: $driveUpload
        type: $type
      ) {
        total
        csvURL
        googleDrive
        questionnaires {
          id
          type
          createdAt
          answer1
          answer2
          user {
            email
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const GET_WATCH_PROPERTIES = gql`
  query wp(
    $input: FilterArgsType
    $pricingPackage: String
    $active: Boolean
    $scheduledToDelete: Boolean
    $orderBy: String
    $orderByASC: Boolean
    $csvDownload: Boolean
    $countryCode: String
    # $shared: Boolean,
    $driveUpload: Boolean
    $tool: String
  ) {
    Watch {
      adminGetMany(
        input: $input
        pricingPackage: $pricingPackage
        active: $active
        orderBy: $orderBy
        orderByASC: $orderByASC
        scheduledToDelete: $scheduledToDelete
        csvDownload: $csvDownload
        countryCode: $countryCode
        # shared: $shared,
        driveUpload: $driveUpload
        tool: $tool
      ) {
        total
        csvURL
        googleDrive
        wp {
          id
          address
          createdAt
          updatedAt
          active
          userEmail
          ip
          countryCode
          scheduledDeleteAt
          tool
        }
      }
    }
  }
`;

export const WATCH_PROPERTY_SUBSCRIPTIONS_CHART = gql`
  query watchPropertySubscriptions($hidePropertiesFromHex: Boolean) {
    Watch {
      subscriptionsChart(hidePropertiesFromHex: $hidePropertiesFromHex) {
        count
        time
      }
    }
  }
`;

export const WP_TOOLS_PIECHART = gql`
  query toolsPiechart($hideHex: Boolean, $from: String, $to: String) {
    Watch {
      toolsPiechart(hideHex: $hideHex, from: $from, to: $to) {
        field
        count
      }
    }
  }
`;

export const GET_WATCH_NOTIFICATIONS = gql`
  query notifications(
    $input: FilterArgsType
    $channel: String
    $type: String
    $orderBy: String
    $orderByASC: Boolean
    $csvDownload: Boolean
    $driveUpload: Boolean
  ) {
    WatchNotification {
      adminGetMany(
        input: $input
        channel: $channel
        type: $type
        orderBy: $orderBy
        orderByASC: $orderByASC
        csvDownload: $csvDownload
        driveUpload: $driveUpload
      ) {
        total
        csvURL
        googleDrive
        wpNotifications {
          id
          channel
          type
          createdAt
          user {
            email
            firstName
            lastName
          }
          watchProperty {
            address
          }
        }
      }
    }
  }
`;

export const GET_NOTIFICATION_TYPE_OPTIONS = gql`
  query Types {
    WatchNotification {
      watchNotificationTypeFields {
        type
      }
    }
  }
`;

const GetConstantDataArguments = `{
  $hexowatch_notifications_description: Boolean,
}`;

export const GET_CONSTANT_TYPES = gql`
  query ($requested_data: GetConstantDataArguments) {
    Admin {
      getConstantData(requested_data: $requested_data) {
        hexowatch_notifications_description {
          type
          description
        }
        error
        message
      }
    }
  }
`;

export const WATCH_NOTIFICATIONS_CHART = gql`
  query watchNotificationsChart($channel: String) {
    WatchNotification {
      watchNotificationsChart(channel: $channel) {
        time
        count
      }
    }
  }
`;

export const WATCH_NOTIFICATIONS_PIECHART = gql`
  query ($from: String, $to: String, $hideHex: Boolean) {
    WatchNotification {
      watchNotificationsPieChart(from: $from, to: $to, hideHex: $hideHex) {
        field
        count
      }
    }
  }
`;

export const HEXOMATIC_NOTIFICATIONS_PIECHART = gql`
  query ($from: String, $to: String, $hideHex: Boolean) {
    HexomaticNotification {
      hexomaticNotificationsPieChart(from: $from, to: $to, hideHex: $hideHex) {
        field
        count
      }
    }
  }
`;

export const USERS_TYPE_PIECHART = gql`
  query USERS_TYPE_PIECHART($from: String, $to: String) {
    User {
      typesPiechart(from: $from, to: $to) {
        field
        count
      }
    }
  }
`;

export const WATCH_PRICING_PIECHART = gql`
  query watchPricingPiechart($hidePropertiesFromHex: Boolean, $from: String, $to: String) {
    UserWatchSettings {
      watchPricingPackagePiechart(hidePropertiesFromHex: $hidePropertiesFromHex, from: $from, to: $to) {
        field
        count
      }
    }
  }
`;

const GetNotificationEmailsArguments = `{
  $type: String,
  $page: Int,
  $limit: Int,
  $csv_download_link: Boolean,
}`;

export const GET_ADDITIONAL_EMAILS = gql`
  query ($settings: GetNotificationEmailsArguments) {
    Admin {
      getNotificationEmails(settings: $settings) {
        hexowatch_emails {
          user_id
          email
        }
        hexometer_emails {
          user_id
          property_id
          email
        }
        count
        csv_url
        error
        message
      }
    }
  }
`;

export const GET_SUPER_USER_DATA = gql`
  query {
    Admin {
      getSuperUserData {
        token
        error
        message
      }
    }
  }
`;

export const GET_IMPACT_ACTIONS = gql`
  query getImpacts($id: ID!) {
    User {
      getCustomerImpactActions(id: $id) {
        id
      }
    }
  }
`;

export const GET_SHORTLINK_QUERY = gql`
  query ($settings: ShortlinkGetManySettings) {
    Shortlink {
      getMany(settings: $settings) {
        shortlinks {
          id
          created_at
          link
          shortlink
        }
        total_count

        csv_url

        error
        message
      }
    }
  }
`;

//! -------------- hexomatic queries -----------------------------

export const GET_HEXOMATIC_NOTIFICATION_TYPE_OPTIONS = gql`
  query GET_HEXOMATIC_NOTIFICATION_TYPE_OPTIONS($hideHex: Boolean) {
    HexomaticNotification {
      hexomaticNotificationTypes(hideHex: $hideHex) {
        types {
          type
        }
      }
    }
  }
`;

export const GET_AUTOMATION_CATEGORIES = gql`
  query ($settings: GetAutomationCategoriesInput) {
    HexomaticAdmin {
      getAutomationCategories(settings: $settings) {
        categories {
          id
          name
        }
        error_code
      }
    }
  }
`;

export const GET_AUTOMATION_TYPES = gql`
  query ($settings: GetAutomationTypesInput) {
    HexomaticAdmin {
      getAutomationTypes(settings: $settings) {
        types {
          id
          name
        }
        error_code
      }
    }
  }
`;

export const GET_AUTOMATIONS = gql`
  query ($settings: GetAutomationsInput) {
    HexomaticAdmin {
      getAutomations(settings: $settings) {
        automations {
          id
          name
          url
          meta_title
          meta_description
          short_description
          long_description
          long_description_details
          icon_url
          is_new
          active
          has_source
          source {
            source_csv
            source_txt
          }
          inputs
          outputs
          multi
          order
          category {
            id
            name
          }
          type {
            id
            name
          }
          prices {
            id
            unit_name
            credit_price
            premium_credit_price
          }
          suggestAutomations {
            suggestAutomationId
            name
          }
        }
        total
        error_code
      }
    }
  }
`;
export const GET_AUTOMATIONS_ID = gql`
  query ($settings: GetAutomationsInput) {
    HexomaticAdmin {
      getAutomations(settings: $settings) {
        automations {
          id
          name
        }
      }
    }
  }
`;

export const GET_SCRAPING_RECIPES = gql`
  query GET_SCRAPING_RECIPES($settings: GetScrapingRecipesAdminInput) {
    HexomaticAdmin {
      getScrapingRecipes(settings: $settings) {
        scraping_recipes {
          id
          name
          url
          actions
          is_public
          status
          user_email
        }
        total
        error_code
      }
    }
  }
`;

export const GET_PUBLIC_SCRAPING_RECIPES = gql`
  query GET_PUBLIC_SCRAPING_RECIPES($settings: GetPublicScrapingRecipesInput) {
    HexomaticPublicScrapingRecipe {
      getPublicScrapingRecipes(settings: $settings) {
        public_scraping_recipes {
          id
          name
          url
          actions
          is_public
          status
          user_email
          is_new
          most_used
          categories {
            id
            name
          }
        }
        total
        error_code
      }
    }
  }
`;

export const GET_WORKFLOWS = gql`
  query ($settings: GetWorkflowsAdminInput) {
    HexomaticAdmin {
      getWorkflows(settings: $settings) {
        workflows {
          id
          name
          is_public
          status
          steps {
            index
            automation_id
            automation_icon_url
            automation_name
          }
          user_email
          error_code
        }
        total
        error_code
      }
    }
  }
`;

export const GET_PUBLIC_WORKFLOWS = gql`
  query GET_PUBLIC_WORKFLOWS($settings: GetPublicWorkflowsInput) {
    HexomaticPublicWorkflow {
      getPublicWorkflows(settings: $settings) {
        public_workflows {
          id
          name
          status
          started_at
          frequency
          created_at
          updated_at
          description
          meta_title
          meta_description
          meta_description
          image
          most_used
          categories {
            id
            name
          }
          steps {
            id
            setting
            index
            created_at
            updated_at
            automation {
              id
              name
              url
              meta_title
              meta_description
              short_description
              long_description
              has_source
              icon_url
            }
          }
        }
        count
        error_code
      }
    }
  }
`;

export const GET_SCHEMAS = gql`
  query ($settings: GetJsonSchemasAdminInput) {
    HexomaticAdmin {
      getJsonSchemas(settings: $settings) {
        json_schemas {
          id
          schema
          comment
          created_at
          updated_at
        }
        error_code
      }
    }
  }
`;

export const GET_AUTOMATION_WITH_IO = gql`
  query ($settings: GetAutomationsWithIOInput) {
    HexomaticAdmin {
      getAutomationsWithIO(settings: $settings) {
        automations {
          id
          name
          short_description
          active
          input_schema_ids
          output_schema_ids
        }
        error_code
      }
    }
  }
`;

export const GET_AUTOMATION_UNITS = gql`
  query ($settings: GetAutomationUnitsInput) {
    HexomaticAdmin {
      getAutomationUnits(settings: $settings) {
        units {
          id
          name
          created_at
          updated_at
        }
        error_code
      }
    }
  }
`;

export const GET_PIECHART_TRAFFIC_AND_CREDITS = gql`
  query GET_PIECHART_TRAFFIC_AND_CREDITS($settings: PieChartTrafficAndCreditsInput) {
    HexomaticAdmin {
      pieChartTrafficAndCredits(settings: $settings) {
        pie_chart_data {
          data_center
          residential
          residential_geo

          credit
          premium_credit
        }
        error_code
      }
    }
  }
`;

export const GET_HEXOWATCH_PIECHART_TRAFFIC_AND_CREDITS = gql`
  query ($from: String, $to: String, $hideHex: Boolean) {
    Watch {
      watchTrafficChart(from: $from, to: $to, hideHex: $hideHex) {
        error
        message
        watchTrafficChart {
          residential_geo
          data_center
        }
      }
    }
  }
`;

export const GET_HEXOWATCH_SCAN_TOOL_PIECHART = gql`
  query GET_HEXOWATCH_SCAN_TOOL_PIECHART($from: String, $to: String, $hideHex: Boolean) {
    Watch {
      getWatchToolScanPieChart(from: $from, to: $to, hideHex: $hideHex) {
        field
        count
      }
    }
  }
`;

export const GET_HEXOWATCH_PREMIUM_CREDIT_PIECHART = gql`
  query GET_HEXOWATCH_SCAN_TOOL_PIECHART($from: String, $to: String, $hideHex: Boolean) {
    Watch {
      watchPremiumCreditPieChart(from: $from, to: $to, hideHex: $hideHex) {
        premium_credits
      }
    }
  }
`;

export const GET_PIECHART_AUTOMATIONS = gql`
  query GET_PIECHART_AUTOMATIONS($from: String, $to: String) {
    HexomaticAutomation {
      getAutomationsChart(to: $to, from: $from) {
        count
        field
      }
    }
  }
`;

export const GET_HACKATHON_SITES_CHART = gql`
  query GET_HACKATHON_SITES_CHART($from: String, $to: String) {
    ExternalPages {
      getExternalPagesChart(to: $to, from: $from) {
        count
        field
      }
    }
  }
`;

export const GET_GRAPH_TRAFFIC_AND_CREDITS = gql`
  query GET_GRAPH_TRAFFIC_AND_CREDITS($settings: TimeSeriesTrafficAndCreditsInput) {
    HexomaticAdmin {
      timeSeriesTrafficAndCredits(settings: $settings) {
        time_series {
          date

          data_center
          residential
          residential_geo

          credit
          premium_credit
        }

        error_code
      }
    }
  }
`;

export const GET_USER_DAILY_TRAFFIC_AND_CREDIT = gql`
  query GET_USER_DAILY_TRAFFIC_AND_CREDIT($settings: UsersDailyStatsInput) {
    HexomaticAdmin {
      usersDailyStats(settings: $settings) {
        daily_stats # stringified array
        hc_daily_stats_count
        error_code
        csvURL
        googleDrive
      }
    }
  }
`;

export const GET_HEXOMATIC_NOTIFICATIONS = gql`
  query (
    $input: FilterArgsType
    $channel: String
    $type: String
    $orderBy: String
    $orderByASC: Boolean
    $csvDownload: Boolean
    $driveUpload: Boolean
    $hideHex: Boolean
  ) {
    HexomaticNotification {
      adminGetMany(
        input: $input
        channel: $channel
        type: $type
        orderBy: $orderBy
        orderByASC: $orderByASC
        csvDownload: $csvDownload
        driveUpload: $driveUpload
        hideHex: $hideHex
      ) {
        total
        csvURL
        googleDrive
        notifications {
          id
          user {
            email
            firstName
            lastName
          }
          workflow {
            id
            name
            is_public
            status
            started_at
            frequency
            created_at
            updated_at
          }
          channel
          type
          createdAt
        }
      }
    }
  }
`;

export const HEXOMATIC_NOTIFICATIONS_CHART = gql`
  query ($channel: String) {
    HexomaticNotification {
      hexomaticNotificationsChart(channel: $channel) {
        time
        count
      }
    }
  }
`;
export const GET_HEXOMATIC_TASKS = gql`
  query GET_TASKS($settings: getHumanValidationTasksType) {
    HexomaticHumanValidationsTasks {
      adminGetHumanValidationsTasks(settings: $settings) {
        tasks {
          id
          question
          status
          created_at
          quantity
          creator {
            firstName
            lastName
            email
          }
          performer {
            firstName
            lastName
            email
          }
          duration
          startDate
          endDate
        }
        count
      }
    }
  }
`;

export const GET_HEXOMATIC_TASK = gql`
  query GET_TASK($id: Int) {
    HexomaticHumanValidationsTasks {
      adminGetHumanValidationsTask(id: $id) {
        question
        status
        price
        created_at
        updated_at
        legacy_id
        type
        elements {
          link
          answer
        }
      }
    }
  }
`;

export const HEXOMATIC_TASKS_CHART = gql`
  query ($from: String, $to: String, $hideHex: Boolean) {
    HexomaticHumanValidationsTasks {
      adminGetHumanValidationTaskStatusChart(from: $from, to: $to, hideHex: $hideHex) {
        field
        count
      }
    }
  }
`;

export const HEXOMATIC_TASKS_USERS_CHART = gql`
  query ($from: String, $to: String) {
    HexomaticHumanValidationsTasks {
      adminGetHumanValidationTaskUsersChart(from: $from, to: $to) {
        creators
        performers
      }
    }
  }
`;
export const CHANGE_IS_PERFORMER = gql`
  mutation ($userId: Int, $enabled: Boolean) {
    HexomaticHumanValidationsTasksOps {
      enableUserToPerformHumanValidationTask(userId: $userId, enabled: $enabled) {
        error
      }
    }
  }
`;
export const SHORTHCUT_CHART = gql`
  query ($from: String, $to: String) {
    ShortcutLog {
      getShortcutLogPieChart(from: $from, to: $to) {
        field
        count
      }
    }
  }
`;

export const WORKFLOW_ANALYTICS = gql`
  query ($from: String, $to: String, $hideHex: Boolean) {
    HexomaticWorkflow {
      getWorkflowChart(from: $from, to: $to, hideHex: $hideHex) {
        field
        count
      }
    }
  }
`;

// query {
//   HexomaticAutomation {
//     getAutomationUsageInfo(settings:{sortField:"automation_id", sortDir:"DESC" pageSize:8, automationSearchQuery:"text" }) {
//       storage
//       credits
//       premiumCredits
//       name
//       firstName
//       lastName
//       email
//       created_at
//       user_id
//     }
//   }
// }

export const GET_HEXOMATIC_AUTOAMTION_USAGE = gql`
  query GET_HEXOMATIC_AUTOAMTION_USAGE($settings: AutomationCreditTimeSeriesSettings) {
    HexomaticAutomation {
      getAutomationCreditUsage(settings: $settings) {
        data
      }
    }
  }
`;

export const GET_AUTOMATION_CREDIT_TIME_SERIES = gql`
  query GET_AUTOMATION_CREDIT_TIME_SERIES($settings: AutomationCreditTimeSeriesSettings) {
    HexomaticAutomation {
      getAutomationCreditTimeSeries(settings: $settings) {
        time_series
        error
        message
      }
    }
  }
`;

export const GET_AUTOMATION_PREMIUM_CREDIT_TIME_SERIES = gql`
  query GET_AUTOMATION_PREMIUM_CREDIT_TIME_SERIES($settings: AutomationCreditTimeSeriesSettings) {
    HexomaticAutomation {
      getAutomationPremiumCreditTimeSeries(settings: $settings) {
        time_series
        error
        message
      }
    }
  }
`;

export const GET_HEXOMATIC_AUTOMATION_NAMES = gql`
  query GET_HEXOMATIC_AUTOMATION_NAMES {
    HexomaticAutomation {
      getAutomationNames {
        names {
          id
          name
        }
      }
    }
  }
`;

export const GET_AUTOMATIONS_CREDIT_USAGES = gql`
  query ($settings: AutomationUsageInput) {
    HexomaticAutomation {
      getAutomationUsageInfo(settings: $settings) {
        automationsUsage {
          storage
          credits
          premiumCredits
          name
          firstName
          lastName
          email
          created_at
          user_id
        }
        count
        csvURL
        googleDrive
      }
    }
  }
`;

export const GET_AUTOMATIONS_CREDIT_USAGES_DATA = gql`
  query ($settings: AutomationCreditUsageInfoSettings) {
    HexomaticAutomation {
      getAutomationCreditUsageInfo(settings: $settings) {
        credit
        premium_credit
        csvURL
      }
    }
  }
`;

export const SHORTHCUT_CLICKS = gql`
  query getShortcutLogs($settings: GetShortcutLogSettings) {
    ShortcutLog {
      getShortcutLogs(settings: $settings) {
        shortcutLogs {
          type
          shortcut_log_id
          created_at
          firstName
          lastName
          email
          user_id
        }
        count
        csvUrl
        driveUpload
      }
    }
  }
`;
export const SHORTHCUT_CLICKS_CHART = gql`
  query getShortcutLogTimeSeries($hideHex: Boolean) {
    ShortcutLog {
      getShortcutLogTimeSeries(hideHex: $hideHex) {
        count
        time
        day
        month
        year
      }
    }
  }
`;
export const MOBILE_INVITES = gql`
  query ($settings: GetReferralsInput) {
    Referral {
      getReferrals(settings: $settings) {
        referrals {
          id
          action
          created_at
          referrerUserEmail
          userEmail
          referralCode
        }
        count
        csvUrl
        driveUpload
      }
    }
  }
`;

export const GET_REGISTRATION_FROM_INVITE = gql`
  query ($from: String, $to: String) {
    Referral {
      getReferralChart(to: $to, from: $from) {
        count
        field
      }
    }
  }
`;

//hakaton queries

export const PULLCONTACT_CHART = gql`
  query getPullContactsTimeSeries($from: String, $to: String) {
    PullContact {
      getPullContactsTimeSeries(from: $from, to: $to) {
        timeSeries {
          count
          time
        }
      }
    }
  }
`;

export const GET_PULLCONTACT_DATA = gql`
  query getPullContactsData(
    $filter: GetDiscoverProfileFilter
    $sortSettings: ISortSettings
    $pageSettings: IPageSettings
  ) {
    PullContact {
      getPullContactsData(filter: $filter, sortSettings: $sortSettings, pageSettings: $pageSettings) {
        pullContacts {
          source
          ip
          createdAt
          status
        }
        count
      }
    }
  }
`;

export const FULLPAGESCREENCAPTURE_CHART = gql`
  query ($from: String, $to: String) {
    FullPageScreen {
      getFullPageScreenTimeSeries(from: $from, to: $to) {
        timeSeries {
          count
          time
        }
      }
    }
  }
`;

export const FULLPAGESCREENCAPTURE_DATA = gql`
  query ($filter: GetDiscoverProfileFilter, $sortSettings: ISortSettings, $pageSettings: IPageSettings) {
    FullPageScreen {
      getFullPageScreenData(filter: $filter, sortSettings: $sortSettings, pageSettings: $pageSettings) {
        fullPageScreenData {
          source
          ip
          createdAt
          status
        }
        count
        googleDrive
        csvURL
      }
    }
  }
`;

export const DISCOVERPROFILE_CHART = gql`
  query getDiscoverProfileTimeSeries($from: String, $to: String) {
    DiscoverProfile {
      getDiscoverProfileTimeSeries(from: $from, to: $to) {
        timeSeries {
          count
          time
        }
      }
    }
  }
`;

export const GET_DISCOVERPROFILE_DATA = gql`
  query getDiscoverProfileData(
    $filter: GetDiscoverProfileFilter
    $sortSettings: ISortSettings
    $pageSettings: IPageSettings
  ) {
    DiscoverProfile {
      getDiscoverProfileData(filter: $filter, sortSettings: $sortSettings, pageSettings: $pageSettings) {
        discoverProfiles {
          source
          ip
          createdAt
          status
        }
        csvURL
        googleDrive
      }
    }
  }
`;

export const WEBSITEWORDCOUNT_CHART = gql`
  query getWebsiteWordCountTimeSeries($from: String, $to: String) {
    WebsiteWordCount {
      getWebsiteWordCountTimeSeries(from: $from, to: $to) {
        timeSeries {
          count
          time
        }
      }
    }
  }
`;

export const GET_WEBSITEWORDCOUNT_DATA = gql`
  query getWebsiteWordCountData(
    $filter: GetDiscoverProfileFilter
    $sortSettings: ISortSettings
    $pageSettings: IPageSettings
  ) {
    WebsiteWordCount {
      getWebsiteWordCountData(filter: $filter, sortSettings: $sortSettings, pageSettings: $pageSettings) {
        websiteWordCounts {
          source
          ip
          createdAt
          status
        }
        count
      }
    }
  }
`;
export const FREE_TOOLS = gql`
  query getFreeToolsData(
    $settings: FreeToolsDataSettings
    $filter: GetFreeToolsFilter
    $sortSettings: ISortSettings
    $pageSettings: IPageSettings
  ) {
    FreeTools {
      getFreeToolsData(
        settings: $settings
        filter: $filter
        sortSettings: $sortSettings
        pageSettings: $pageSettings
      ) {
        freeTools {
          ip
          source
          status
          toolName
          socketId
          createdAt
          userId
          userEmail
          requestSource
        }
        count
        csvURL
        googleDrive
      }
    }
  }
`;
export const FREE_TOOLS_CHART = gql`
  query getFreeToolsTimeSeries($from: String, $to: String, $hideHex: Boolean, $requestSource: String) {
    FreeTools {
      getFreeToolsTimeSeries(from: $from, to: $to, hideHex: $hideHex, requestSource: $requestSource) {
        count
        time
        #year
      }
    }
  }
`;
export const GET_FREE_TOOLS_AND_EXTENSION_CHART = gql`
  query GET_FREE_TOOLS_AND_EXTENSION_CHART(
    $from: String
    $to: String
    $hideHex: Boolean
    $requestSource: String
  ) {
    FreeTools {
      getFreeToolsChart(to: $to, from: $from, hideHex: $hideHex, requestSource: $requestSource) {
        count
        field
      }
    }
  }
`;
export const GET_FREE_TOOLS_AND_EXTENSION_STATUS_CHART = gql`
  query GET_FREE_TOOLS_AND_EXTENSION_STATUS_CHART(
    $from: String
    $to: String
    $hideHex: Boolean
    $requestSource: String
  ) {
    FreeTools {
      getFreeToolsStatusChart(to: $to, from: $from, hideHex: $hideHex, requestSource: $requestSource) {
        count
        field
      }
    }
  }
`;
export const GET_FREE_TOOLS_NAME = gql`
  query {
    FreeTools {
      getFreeToolsNames {
        name
      }
    }
  }
`;
export const GET_LIST_PRODUCTS = gql`
  query GET_LIST_PRODUCTS($settings: ListProductsInput) {
    Billing {
      listProducts(settings: $settings) {
        products {
          hexomaticProducts {
            id
            name
            interval
            productType
          }
          hexometerProducts {
            id
            name
            productName
            interval
            productId
            productType
          }
          hexowatchProducts {
            id
            name
            product_name
            interval
            productId
            productType
          }
          hexosparkProducts {
            id
            name
            priceId
            interval
            productId
            productType
          }
          hexofyProducts {
            id
            name
            priceId
            interval
            productId
            productType
          }

          premiumCreditProducts {
            name
            free
            limits {
              premium_credits_count
            }
            enable
            interval
            productId
            priceId
            amount
            id
            productType
          }
        }
        error_code
      }
    }
  }
`;

//Public workflow categories

export const GET_PUBLIC_WORKFLOW_CATEGORIES = gql`
  query {
    HexomaticWorkflowCategory {
      getWorkflowCategories {
        categories {
          id
          name
          long_description
          short_description
          image
          created_at
        }
      }
    }
  }
`;

//Email custom messages

export const GET_EMAIL_CUSTOM_MESSAGE = gql`
  query ($filter: GetCustomMessagesFilterInput, $pageSettings: IPageSettings, $sortSettings: ISortSettings) {
    CustomMessage {
      getCustomMessages(filter: $filter, pageSettings: $pageSettings, sortSettings: $sortSettings) {
        customMessages {
          id
          variables {
            img_url
            subject
            description
            href
            product
            date
          }
          templates_id
        }
        error_code
        message
      }
    }
  }
`;

//Shared scraping recipes

export const GET_HEXOMATIC_SHARED_RECIPES = gql`
  query ($settings: GetSharedScrapingRecipesReportSettings) {
    HexomaticScrapingRecipe {
      getSharedScrapingRecipesReport(settings: $settings) {
        sharedRecipes {
          original_recipe_id
          user_id
          user_email
          number_of_shares
          numberOfImports
          usersSharedWith {
            user_id
            user_email
          }
          usersImportedBy {
            user_id
            user_email
          }
        }
        count
        csvURL
        googleDrive
      }
    }
  }
`;

export const GET_HEXOMATIC_SHARED_RECIPES_CHART = gql`
  query ($from: String, $to: String, $hideHex: Boolean) {
    HexomaticScrapingRecipe {
      getSharedScrapingRecipesChart(from: $from, to: $to, hideHex: $hideHex) {
        shares {
          day
          month
          year
          time
          count
        }
        imports {
          day
          month
          year
          time
          count
        }
      }
    }
  }
`;

export const GET_SCRAPING_CATEGORIES = gql`
  query GET_SCRAPING_CATEGORIES {
    HexomaticScrapingCategory {
      getScrapingCategories {
        categories {
          id
          name
          created_at
          short_description
          long_description
          image
        }
      }
    }
  }
`;

//Shared workflows

export const GET_HEXOMATIC_SHARED_WORKFLOWS = gql`
  query ($settings: GetSharedWorkflowsReportSettings) {
    HexomaticWorkflow {
      getSharedWorkflowsReport(settings: $settings) {
        sharedWorkflows {
          original_workflow_id
          user_id
          user_email
          number_of_shares
          numberOfImports
          usersSharedWith {
            user_id
            user_email
          }
          usersImportedBy {
            user_id
            user_email
          }
        }
        count
        csvURL
        googleDrive
      }
    }
  }
`;

export const GET_HEXOMATIC_SHARED_WORKFLOWS_CHART = gql`
  query ($from: String, $to: String, $hideHex: Boolean) {
    HexomaticWorkflow {
      getSharedWorkflowsChart(from: $from, to: $to, hideHex: $hideHex) {
        shares {
          day
          month
          year
          time
          count
        }
        imports {
          day
          month
          year
          time
          count
        }
      }
    }
  }
`;

export const GET_RANDOM_TIME_AND_COUNT = gql`
  query GET_RANDOM_TIME_AND_COUNT {
    Admin {
      getRandomTimeAndCountConfig {
        error
        times
        counts
      }
    }
  }
`;

export const GET_RANDOM_COUNT_PREMIUMS = gql`
  query GET_RANDOM_COUNT_PREMIUMS {
    Admin {
      getPromoLeftSeats {
        error_code
        seats
      }
    }
  }
`;

export const RUN_RUNDOM_TIME_AND_COUNTS = gql`
  query RUN_RUNDOM_TIME_AND_COUNTS {
    Admin {
      runRandomTimeAndCounts {
        error
      }
    }
  }
`;

export const LIST_PREMIUM_CREDITS = gql`
  query LIST_PREMIUM_CREDITS {
    Billing {
      listPremiumCredits {
        premiumCredits {
          name
          free
          limits {
            premium_credits_count
          }
          enable
          interval
          productId
          amount
          id
        }
        error_code
      }
    }
  }
`;

export const GET_EXCLUDED_USERS = gql`
  query GET_EXCLUDED_USERS($settings: GetExcludedUsersSettings) {
    ExcludedUser {
      getExcludedUsers(settings: $settings) {
        excludedUsers {
          id
          main_postgres_user_id
          hc_mongo_user_id
          hm_mongo_user_id
          watch_mongo_user_id
        }
        total
        csvUrl
        googleDrive
      }
    }
  }
`;

// Automation input/output types

export const GET_AUTOMATION_INPUT_OUTPUT_TYPES = gql`
  query GET_AUTOMATION_INPUT_OUTPUT_TYPES {
    HexomaticAdmin {
      getAutomationInputTypes {
        types {
          id
          name
        }
        total
        error_code
      }
    }
  }
`;

export const GET_AUTOMATION_INPUT_OUTPUT_TYPE = gql`
  query GET_AUTOMATION_INPUT_OUTPUT_TYPE($settings: GetAutomationInputTypeInput) {
    HexomaticAdmin {
      getAutomationInputType(settings: $settings) {
        id
        name
        error_code
      }
    }
  }
`;

export const GET_HEXOSPARK_ICE_BREAKERS = gql`
  query GET_HEXOSPARK_ICE_BREAKERS {
    HexosparkAdmin {
      getCrmIceBreakersAdmin {
        result {
          id
          body
          category
        }
        error_code
      }
    }
  }
`;

export const GET_HEXOSPARK_PUBLIC_TEMPLATES = gql`
  query GET_HEXOSPARK_PUBLIC_TEMPLATES($settings: AdminGetPublicEmailTemplatesInput) {
    HexosparkAdmin {
      adminGetPublicEmailTemplates(settings: $settings) {
        publicEmailTemplates {
          id
          created_at
          name
          subject
          body
          categories {
            id
            name
            value: id
            label: name
          }
          attachedFiles {
            id
            size
            name
            link
            type
            gc_directory_path
          }
        }
        count
        error_code
      }
    }
  }
`;

export const GET_HEXOSPARK_PUBLIC_TEMPLATES_CATEGORIES = gql`
  query GET_HEXOSPARK_PUBLIC_TEMPLATES_CATEGORIES($settings: AdminGetPublicEmailTemplatesInput) {
    HexosparkAdmin {
      adminGetPublicEmailTemplateCategories(settings: $settings) {
        categories {
          id
          name
        }
        count
        error_code
      }
    }
  }
`;

export const GET_HEXOSPARK_WORKSPACES = gql`
  query GET_HEXOSPARK_WORKSPACES($settings: AdminGetManyReportSettings) {
    HexosparkAdmin {
      getHsWorkspacesPerUserAdmin(settings: $settings) {
        result {
          main_user_id
          main_user_email
          hs_workspace_count
        }
        count
        error_code
      }
    }
  }
`;

export const GET_HEXOSPARK_CONTACTS_OR_ORGANIZATION = gql`
  query GET_HEXOSPARK_CONTACTS_OR_ORGANIZATION($settings: AdminGetManyReportSettings) {
    HexosparkAdmin {
      getHsWorkspacesWithContactOrganizationCount(settings: $settings) {
        hsWorkspacesWithContactOrganizationCount {
          main_user_id
          main_user_email
          crm_contact_count
          crm_organization_count
          hs_workspace_id
        }
      }
    }
  }
`;

export const GET_HEXOSPARK_CAMPAIGNS = gql`
  query GET_HEXOSPARK_CAMPAIGNS($settings: AdminGetManyReportSettings) {
    HexosparkAdmin {
      getCampaignsAdmin(settings: $settings) {
        count
        error_code
        campaigns {
          hs_workspace_id
          id
          created_at
          name
          sended_emails_count
          replied_emails_count
          failed_emails_count
          main_user_id
          main_user_email
          sender_emails {
            id
            email
          }
        }
      }
    }
  }
`;

export const GET_HEXOSPARK_INTEGRATIONS = gql`
  query GET_HEXOSPARK_INTEGRATIONS($settings: AdminGetManyReportSettings) {
    HexosparkAdmin {
      getIntegrationsAdmin(settings: $settings) {
        count
        error_code
        integrations {
          main_user_id
          main_user_email
          gmails_count
          smtp_count
          hs_workspace_id
        }
      }
    }
  }
`;

export const GET_HEXOSPARK_STORAGE_PER_WORKSPACE = gql`
  query GET_HEXOSPARK_STORAGE_PER_WORKSPACE($settings: AdminGetManyReportSettings) {
    HexosparkAdmin {
      getStorageUsagePerWorkspaceAdmin(settings: $settings) {
        result {
          main_user_id
          main_user_email
          storage
          hs_workspace_id
        }
        count
        error_code
      }
    }
  }
`;

export const GET_HEXOSPARK_STORAGE_PER_WORKSPACE_TIME_SERIES = gql`
  query GET_HEXOSPARK_STORAGE_PER_WORKSPACE_TIME_SERIES($settings: AdminGetManyReportSettings) {
    HexosparkAdmin {
      getStorageUsageTimeSeriesAdmin(settings: $settings) {
        timeSeries {
          count
          time
        }
        error_code
      }
    }
  }
`;

export const HEXOSPARK_PRICING_PIECHART = gql`
  query HEXOSPARK_PRICING_PIECHART($settings: GetHsUserPricingPackagePieChartInput) {
    HexosparkAdmin {
      getHsUserPricingPackagePieChart(settings: $settings) {
        result {
          field
          count
        }
        error_code
      }
    }
  }
`;

export const HEXOSPARK_STORAGE_PIECHART = gql`
  query HEXOSPARK_STORAGE_PIECHART($settings: AdminGetManyReportSettings) {
    HexosparkAdmin {
      getStorageUsagePieChart(settings: $settings) {
        storage
        error_code
      }
    }
  }
`;

export const GET_HEXOSPARK_EMAIL_TEMPLATES_PER_WORKSPACE = gql`
  query GET_HEXOSPARK_EMAIL_TEMPLATES_PER_WORKSPACE($settings: AdminGetManyReportSettings) {
    HexosparkAdmin {
      getEmailTemplatesPerWorkspace(settings: $settings) {
        result {
          main_user_id
          main_user_email
          email_template_count
          hs_workspace_id
        }
        count
        error_code
      }
    }
  }
`;

export const GET_HEXOSPARK_CAMPAIGN_STATUSES_PIE_CHART = gql`
  query GET_HEXOSPARK_CAMPAIGN_STATUSES_PIE_CHART($settings: GetEmailStatusesPieChartInput) {
    HexosparkAdmin {
      getCampaignStatusesPieChart(settings: $settings) {
        result {
          field
          count
        }
        error_code
      }
    }
  }
`;

export const GET_HEXOSPARK_EMAIL_STATUSES_PIE_CHART = gql`
  query GET_HEXOSPARK_EMAIL_STATUSES_PIE_CHART($settings: GetEmailStatusesPieChartInput) {
    HexosparkAdmin {
      getEmailStatusesPieChart(settings: $settings) {
        result {
          field
          count
        }
        error_code
      }
    }
  }
`;

export const GET_HEXOSPARK_EXTENSION_CONTACT_COUNT = gql`
  query GET_HEXOSPARK_EXTENSION_CONTACT_COUNT($settings: GetContactsFromExtensionCountSettings) {
    HexosparkAdmin {
      getContactsFromExtensionCount(settings: $settings) {
        count
        error_code
      }
    }
  }
`;

export const GET_HEXOSPARK_EXTENSION_ORGANIZATION_COUNT = gql`
  query GET_HEXOSPARK_EXTENSION_ORGANIZATION_COUNT($settings: GetContactsFromExtensionCountSettings) {
    HexosparkAdmin {
      getOrganizationsFromExtensionCount(settings: $settings) {
        count
        error_code
      }
    }
  }
`;
//HotJar
export const GENERATE_HOT_JAR_CSV = gql`
  query GENERATE_HOT_JAR_CSV($settings: GenerateHotjarCsvInput) {
    Admin {
      generateHotjarCsv(settings: $settings) {
        newCsvUrl
        error_code
      }
    }
  }
`;

export const CANCELLATION_FEEDBACKS = gql`
  query CANCELLATION_FEEDBACKS($settings: GetCancellationsInput) {
    Billing {
      getCancellations(settings: $settings) {
        data {
          first_name
          last_name
          email
          description
        }
        csvUrl
        count
        error_code
      }
    }
  }
`;

export const STARTENGINE_REPORT = gql`
  query STARTENGINE_REPORT($settings: GetStartEngineReportInput) {
    Admin {
      getStartEngineReport(settings: $settings) {
        data {
          id
          first_name
          last_name
          email
          created_at
          referrer
        }
        count
        csvUrl
        error_code
      }
    }
  }
`;

export const DEMO_REQUESTS_REPORT = gql`
  query DEMO_REQUESTS_REPORT($settings: GetStartEngineReportInput) {
    Admin {
      getDemoRequestReport(settings: $settings) {
        data {
          first_name
          last_name
          email
          description
          position
          organization
          country
        }
        count
        csvUrl
        error_code
      }
    }
  }
`;

export const GET_SURVEY_REPORT = gql`
  query GET_SURVEY_REPORT($settings: GetCancellationsInput) {
    Admin {
      getSurveyReport(settings: $settings) {
        data {
          skipped
          firstname
          lastname
          id
          email
          answers {
            question
            answer
          }
        }
        csvUrl
        count
        error_code
      }
    }
  }
`;

export const GET_USERS_REPORT_DOWNLOAD_STATUS = gql`
  query GET_USERS_REPORT_DOWNLOAD_STATUS {
    User {
      getCsvUrlInfo {
        total
        csvURL
        googleDrive
        csvDownload
        driveUpload
        error
        message
      }
    }
  }
`;
