import React, { Fragment, useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Grid, Form, Loader } from "tabler-react";
import moment from "moment";
import DailyMetrics from "./index";
import ChartWithIntervals from "../../components/chartWithIntervals";
import {
  USERS_REGISTRATION_CHART,
  SCANREQUESTLOG_CHART,
  // PROPERTY_SUBSCRIPTIONS_CHART,
  USER_LOGS_CHART,
} from "../../graphql/queries";
// import AnalyticsChart from "../../components/chart/analyticsChart";
import { getCountArr } from "../../helpers";

import "./style.css";
import { setDate } from "../../components/piechart";

const MetricQueries = () => {
  const [filterHexUsers, setFilterHexUsers] = useState(true);

  // const [scanLogsChartData, setScanLogsChartData] = useState([]);
  // const [webScanLogsChartData, setWebScanLogsChartData] = useState([]);
  // const [propertiesChartData, setPropertiesChartData] = useState([]);
  const [userLogsChartData, setUserLogsChartData] = useState([]);
  const [usersRegChartData, setUsersRegChartData] = useState([]);
  const [registrationFrom, setRegistrationFrom] = useState<string | null>(setDate(6).toISOString());
  const [registrationTo, setRegistrationTo] = useState<string | null>(
    new Date(setDate(0).setUTCHours(23, 59, 59, 999)).toISOString()
  );
  const [logsFrom, setLogsFrom] = useState<string | null>(setDate(6).toISOString());
  const [logsTo, setLogsTo] = useState<string | null>(
    new Date(setDate(0).setUTCHours(23, 59, 59, 999)).toISOString()
  );
  const [registrationFrequency, setRegistrationFrequency] = useState("daily");
  const [logsFrequency, setLogsFrequency] = useState("daily");

  const [intervalRegistrationName, setIntervalRegistrationName] = useState("Last 7 days");
  const [intervalLogsName, setIntervalLogsName] = useState("Last 7 days");

  const regUsersChart = useQuery(USERS_REGISTRATION_CHART, {
    variables: {
      frequency: registrationFrequency,
      from: registrationFrom,
      to: registrationTo,
    },
  });
  // const scanLogsChart = useQuery(SCANREQUESTLOG_CHART, {
  //   variables: {
  //     hideHexScans: filterHexUsers,
  //   },
  //   notifyOnNetworkStatusChange: true,
  // });
  const webScanLogsChart = useQuery(SCANREQUESTLOG_CHART, {
    variables: {
      webOnly: true,
      hideHexScans: filterHexUsers,
    },
    notifyOnNetworkStatusChange: true,
  });
  // const propertiesChart = useQuery(PROPERTY_SUBSCRIPTIONS_CHART, {
  //   variables: {
  //     hidePropertiesFromHex: filterHexUsers,
  //   },
  //   notifyOnNetworkStatusChange: true,
  // });

  const userLogsChart = useQuery(USER_LOGS_CHART, {
    variables: {
      hideHexLogins: filterHexUsers,
      from: logsFrom,
      to: logsTo,
      frequency: logsFrequency,
    },
    // fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (
      regUsersChart.data &&
      regUsersChart.data.User &&
      regUsersChart.data.User.registrationChart &&
      regUsersChart.data.User.registrationChart.result
    ) {
      setUsersRegChartData(regUsersChart.data.User.registrationChart.result);
    }
    // if (
    //   scanLogsChart.data &&
    //   scanLogsChart.data.ScanRequestLog &&
    //   scanLogsChart.data.ScanRequestLog.scanRequestLogChart
    // ) {
    //   setScanLogsChartData(scanLogsChart.data.ScanRequestLog.scanRequestLogChart);
    // }
    // if (
    //   webScanLogsChart.data &&
    //   webScanLogsChart.data.ScanRequestLog &&
    //   webScanLogsChart.data.ScanRequestLog.scanRequestLogChart
    // ) {
    //   setWebScanLogsChartData(webScanLogsChart.data.ScanRequestLog.scanRequestLogChart);
    // }
    // if (
    //   propertiesChart.data &&
    //   propertiesChart.data.Property &&
    //   propertiesChart.data.Property.subscriptionsChart
    // ) {
    //   setPropertiesChartData(propertiesChart.data.Property.subscriptionsChart);
    // }
  }, [regUsersChart]);

  useEffect(() => {
    if (userLogsChart.data && userLogsChart.data.UserLog && userLogsChart.data.UserLog.adminUniqueLogsChart) {
      setUserLogsChartData(userLogsChart.data.UserLog.adminUniqueLogsChart);
    }
  }, [userLogsChart]);

  const handleRegistrationFromDate = (date: Date) => {
    if (date) {
      return setRegistrationFrom(date.toISOString());
    }
    return setRegistrationFrom(null);
  };

  const handleRegistrationToDate = (date: Date) => {
    if (date) {
      return setRegistrationTo(date.toISOString());
    }
    return setRegistrationTo(null);
  };

  const handleLogsFromDate = (date: Date) => {
    if (date) {
      return setLogsFrom(date.toISOString());
    }
    return setLogsFrom(null);
  };

  const handleLogsToDate = (date: Date) => {
    if (date) {
      return setLogsTo(date.toISOString());
    }
    return setLogsTo(null);
  };

  const handleRegistrationFrequency = (frequency: string) => setRegistrationFrequency(frequency);
  const handleLogsFrequency = (frequency: string) => setLogsFrequency(frequency);

  return (
    <Fragment>
      <Grid.Row>
        <Grid.Col md={2} className="d-flex">
          <Form.Checkbox
            label="Filter Hexact scans"
            name="example-radios"
            value="option1"
            checked={filterHexUsers}
            onChange={() => setFilterHexUsers(!filterHexUsers)}
            disabled={webScanLogsChart.loading || regUsersChart.loading}
          />
          {(regUsersChart.loading || webScanLogsChart.loading) && <Loader className="loader-small" />}
        </Grid.Col>
      </Grid.Row>
      <DailyMetrics filterHexScans={filterHexUsers} />
      <div className="chart-wrapper-width">
        {
          // <AnalyticsChart
          //   title=' '
          //   interval={getCountArr(usersRegHexometerChartData.length)}
          //   data={usersRegHexometerChartData}
          //   data2={usersRegHexowatchChartData}
          //   data3={usersRegHexomaticChartData}
          //   data4={webScanLogsChartData}
          //   data5={propertiesChartData}
          //   data6={userLogsChartData}
          //   label={'Registered hexometer Users'}
          //   label2={'Registered hexowatch Users'}
          //   label3={'Registered hexomate Users'}
          //   label4={'Scaned Requests'}
          //   label5={'Subscribed Propertie'}
          //   label6={'User Logs'}
          // />
        }

        {!regUsersChart.loading && !webScanLogsChart.loading ? (
          <ChartWithIntervals
            yColName="All Registered Users"
            yColName2="Hexometer Registered Users"
            yColName3="Hexowatch Registered Users"
            yColName4="Hexomatic Registered Users"
            yColName5="Hexospark Registered Users"
            yColName6="Hexofy Registered Users"
            regData={usersRegChartData}
            interval={getCountArr(usersRegChartData.length)}
            chartName="Registered users Hexometer, Hexowatch, Hexomatic, Hexospark, Hexofy"
            propColors={["#000", "#99cc33", "#31b7fb", "#b174fc", "#FF4C25", "#F8C000"]}
            registerChart
            onFromDate={handleRegistrationFromDate}
            onToDate={handleRegistrationToDate}
            onFrequency={handleRegistrationFrequency}
            fromDate={registrationFrom}
            toDate={registrationTo}
            frequency={registrationFrequency}
            intervalName={intervalRegistrationName}
            setIntervalName={(name: string) => setIntervalRegistrationName(name)}
          />
        ) : (
          <div className="d-flex justify-content-center my-4">
            <Loader />
          </div>
        )}

        {/* <AnalyticsChart title='Website Visits' />    */}
        {/* {!regUsersChart.loading && !webScanLogsChart.loading &&
          <AnalyticsChart
            title='Registered Users hexometer, hexowatch, hexomate'
            interval={getCountArr(usersRegHexometerChartData.length)}
            data={usersRegHexometerChartData}
            data2={usersRegHexowatchChartData}
            data3={usersRegHexomaticChartData}
            label={'Hexometer Registered Users'}
            label2={'Hexowatch Registered Users'}
            label3={'Hexomatic Registered Users'}
          />
        } */}
        {!userLogsChart.loading ? (
          <ChartWithIntervals
            yColName="User Logs"
            regData={userLogsChartData}
            chartName="Users Logs Activity"
            interval={getCountArr(userLogsChartData.length)}
            onFromDate={handleLogsFromDate}
            onToDate={handleLogsToDate}
            onFrequency={handleLogsFrequency}
            fromDate={logsFrom}
            toDate={logsTo}
            frequency={logsFrequency}
            intervalName={intervalLogsName}
            setIntervalName={(name: string) => setIntervalLogsName(name)}
          />
        ) : (
          <div className="d-flex justify-content-center my-4">
            <Loader />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default MetricQueries;
