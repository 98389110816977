import React, { FC, useEffect, useState, useMemo } from "react";
import { Grid, Loader, Card, Form } from "tabler-react";
import { DailyMetricsProps } from "../../types";
import { useQuery } from "@apollo/react-hooks";
import {
  SCANREQUESTLOG_PIECHART,
  HEXOMATIC_PRICING_PIECHART,
  USERS_REGISTRATION_PIECHART,
  USERS_WITH_PROPERTIES_PIECHART,
  // PRICING_PIECHART,
  // UPGRADED_PROPERTIES_PIECHART,
  // TASKS_PIECHART,
  NOTIFICATIONS_PIECHART,
  // CHANNELS_PIECHART,
  USERS_BY_COUNTRY_PIECHART,
  WP_TOOLS_PIECHART,
  WATCH_NOTIFICATIONS_PIECHART,
  HEXOMATIC_NOTIFICATIONS_PIECHART,
  USERS_TYPE_PIECHART,
  WATCH_PRICING_PIECHART,
  GET_PIECHART_TRAFFIC_AND_CREDITS,
  // GET_PIECHART_AUTOMATIONS,
  // HEXOMATIC_TASKS_CHART,
  // HEXOMATIC_TASKS_USERS_CHART,
  GET_HACKATHON_SITES_CHART,
  SHORTHCUT_CHART,
  WORKFLOW_ANALYTICS,
  GET_HEXOWATCH_PIECHART_TRAFFIC_AND_CREDITS,
  GET_REGISTRATION_FROM_INVITE,
  GET_HEXOWATCH_SCAN_TOOL_PIECHART,
  GET_HEXOWATCH_PREMIUM_CREDIT_PIECHART,
  GET_FREE_TOOLS_AND_EXTENSION_CHART,
  GET_FREE_TOOLS_AND_EXTENSION_STATUS_CHART,
  HEXOSPARK_PRICING_PIECHART,
  HEXOSPARK_STORAGE_PIECHART,
  GET_HEXOSPARK_CAMPAIGN_STATUSES_PIE_CHART,
  GET_HEXOSPARK_EMAIL_STATUSES_PIE_CHART,
  GET_HEXOSPARK_EXTENSION_CONTACT_COUNT,
  GET_HEXOSPARK_EXTENSION_ORGANIZATION_COUNT,
} from "../../graphql/queries";
import PiechartCard from "../../components/piechart";
import PiechartWithoutDates from "../../components/piechart-without-dates";
import { filterButtons } from "../../components/piechart";
import {
  tools,
  watchPricingPiechart,
  HEXOMATIC_CREDITS_PACKAGE,
  hexowatchTools,
  HEXOSPARK_CREDITS_PACKAGE,
} from "../../constants";

import "./style.css";

const DailyMetrics: FC<DailyMetricsProps> = ({ filterHexScans }) => {
  const [showExtScans, setShowExtScans] = useState(false);
  const [toolsChartFilter, setToolsChartFilter] = useState(filterButtons[0]);
  const [sourceChartFilter, setSourceChartFilter] = useState(filterButtons[0]);
  const [usersChartFilter, setUsersChartFilter] = useState(filterButtons[0]);
  const [withPropertiesChatFilter, setWithPropertiesChatFilter] = useState(filterButtons[0]);
  const [pricingChartFilter, setPricingChartFilter] = useState(filterButtons[0]);
  const [upgradesChartFilter, setUpgradesChartFilter] = useState(filterButtons[0]);
  const [tasksChartFilter, setTasksChartFilter] = useState(filterButtons[0]);
  const [notificationsChartFiler, setNotificationsChartFiler] = useState(filterButtons[0]);
  const [channelsChartFiler, setChannelsChartFiler] = useState(filterButtons[0]);
  const [ctrChartFilter, setCtrChartFilter] = useState(filterButtons[0]);
  const [wpToolsChartFilter, setWpToolsChartFilter] = useState(filterButtons[0]);
  const [wpNotificationsChartFilter, setWpNotificationsChartFilter] = useState(filterButtons[0]);
  const [hexomaticNotificationsChartFilter, setHexomaticNotificationsChartFilter] = useState(
    filterButtons[0]
  );

  const [hexomaticAutomationsUsageChartFilter, setHexomaticAutomationsUsageChartFilter] = useState(
    filterButtons[0]
  );

  const [hackathonSitesChartFilter, setHackathonSitesChartFilter] = useState(filterButtons[0]);
  const [freeToolsChartFilter, setFreeToolsChartFilter] = useState(filterButtons[0]);
  const [extensionChartFilter, setExtensionChartFilter] = useState(filterButtons[0]);
  const [freeToolsStatusChartFilter, setFreeToolsStatusChartFilter] = useState(filterButtons[0]);
  const [registrationFromInviteChartFilter, setRegistrationFromInviteChartFilter] = useState(
    filterButtons[0]
  );

  const [usersTypesChartFilter, setUsersTypesChartFilter] = useState(filterButtons[0]);
  const [watchPricingChartFilter, setWatchPricingChartFilter] = useState(filterButtons[0]);
  const [hexomaticPricingChartFilter, setHexomaticPricingChartFilter] = useState(filterButtons[0]);

  const [hexosparkPricingChartFilter, setHexosparkPricingChartFilter] = useState(filterButtons[0]);
  const [hexosparkCampaignStatusesChartFilter, setHexosparkCampaignStatusesChartFilter] = useState(
    filterButtons[0]
  );
  const [hexosparkEmailStatusesChartFilter, setHexosparkEmailStatusesChartFilter] = useState(
    filterButtons[0]
  );

  const [hexosparkExtContAndOrgCountChartFilter, setHexosparkExtContAndOrgCountChartFilter] = useState(
    filterButtons[0]
  );

  // newly added

  const [trafficUsageChartFilter, setTrafficUsageChartFilter] = useState(filterButtons[0]);
  const [hexowatchtrafficUsageChartFilter, setHexowatchTrafficUsageChartFilter] = useState(filterButtons[0]);
  const [hexowatchScanToolChartFilter, setHexowatchScanToolChartFilter] = useState(filterButtons[0]);
  const [creditsUsageChartFilter, setCreditsUsageChartFilter] = useState(filterButtons[0]);
  //-----------------------hexomatic tasks--------------------------------//
  // const [hexomaticTasksChartFilter, setHexomaticTasksChartFilter] = useState(filterButtons[0]);

  // const [tasksUsersChartFilter, setTasksUsersChartFilter] = useState(filterButtons[0]);
  //-----------------------------------------------------------------------//
  const [shorthCutClicksFilter, setShorthCutClicksFilter] = useState(filterButtons[0]);
  const [workflowAnalitycsFilter, setWorkflowAnalitycsFilter] = useState(filterButtons[0]);
  //----------------------------------------------------------------------//
  const [hexowatchScanToolAndCredit, setHexowatchScanToolAndCredit] = useState([]);
  const [hexosparkExtContAndOrgCount, setHexosparkExtContAndOrgCount] = useState([]);

  const [hexosparkStorageDBValue, setHexosparkStorageDBValue] = useState("US");
  const [hexosparkCampaignDBValue, setHexosparkCampaignDBValue] = useState("US");
  const [hexosparkEmailStatusesDBValue, setHexosparkEmailStatusesDBValue] = useState("US");
  const [hexosparkExtContCountDBValue, setHexosparkExtContCountDBValue] = useState("US");

  const usersTypesChartDataQuery = useQuery(USERS_TYPE_PIECHART, {
    variables: {
      from: usersTypesChartFilter.fromDate,
      to: usersTypesChartFilter.toDate,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: HexomaticPricingChartDataQuery,
    loading: HexomaticPricingChartDataQueryLoading,
    refetch: refetchHexomaticPricingChartData,
  } = useQuery(HEXOMATIC_PRICING_PIECHART, {
    variables: {
      hideHex: filterHexScans,
      from: hexomaticPricingChartFilter.fromDate,
      to: hexomaticPricingChartFilter.toDate,
    },
    fetchPolicy: "network-only",
    // pollInterval: 60000,
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: HexosparkPricingChartDataQuery,
    loading: HexosparkPricingChartDataQueryLoading,
    refetch: refetchHexosparkPricingChartData,
  } = useQuery(HEXOSPARK_PRICING_PIECHART, {
    variables: {
      settings: {
        hideHex: filterHexScans,
        from: hexosparkPricingChartFilter.fromDate,
        to: hexosparkPricingChartFilter.toDate,
      },
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: HexosparkStorageDataQuery,
    loading: HexosparkStorageDataQueryLoading,
    refetch: refetchHexosparkStorageChartData,
  } = useQuery(HEXOSPARK_STORAGE_PIECHART, {
    variables: {
      settings: {
        dbLocation: hexosparkStorageDBValue,
        hideHex: filterHexScans,
      },
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: HexosparkCampaignStatusesDataQuery,
    loading: HexosparkCampaignStatusesDataQueryLoading,
    refetch: refetchHexosparkCampaignStatusChartData,
  } = useQuery(GET_HEXOSPARK_CAMPAIGN_STATUSES_PIE_CHART, {
    variables: {
      settings: {
        dbLocation: hexosparkCampaignDBValue,
        hideHex: filterHexScans,
        from: hexosparkCampaignStatusesChartFilter.fromDate,
        to: hexosparkCampaignStatusesChartFilter.toDate,
      },
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: HexosparkEmailStatusesDataQuery,
    loading: HexosparkEmailStatusesDataQueryLoading,
    refetch: refetchHexosparkEmailStatusesChartData,
  } = useQuery(GET_HEXOSPARK_EMAIL_STATUSES_PIE_CHART, {
    variables: {
      settings: {
        dbLocation: hexosparkEmailStatusesDBValue,
        hideHex: filterHexScans,
        from: hexosparkEmailStatusesChartFilter.fromDate,
        to: hexosparkEmailStatusesChartFilter.toDate,
      },
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: HexosparkExtContCountDataQuery,
    loading: HexosparkExtContCountDataQueryLoading,
    refetch: refetchHexosparkExtContCountChartData,
  } = useQuery(GET_HEXOSPARK_EXTENSION_CONTACT_COUNT, {
    variables: {
      settings: {
        dbLocation: hexosparkExtContCountDBValue,
        hideHex: filterHexScans,
        from: hexosparkExtContAndOrgCountChartFilter.fromDate,
        to: hexosparkExtContAndOrgCountChartFilter.toDate,
      },
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: HexosparkExtOrgCountDataQuery,
    loading: HexosparkExtOrgCountDataQueryLoading,
    refetch: refetchHexosparkExtOrgCountChartData,
  } = useQuery(GET_HEXOSPARK_EXTENSION_ORGANIZATION_COUNT, {
    variables: {
      settings: {
        dbLocation: hexosparkExtContCountDBValue,
        hideHex: filterHexScans,
        from: hexosparkExtContAndOrgCountChartFilter.fromDate,
        to: hexosparkExtContAndOrgCountChartFilter.toDate,
      },
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (
      HexosparkExtContCountDataQuery &&
      HexosparkExtContCountDataQuery.HexosparkAdmin &&
      HexosparkExtContCountDataQuery.HexosparkAdmin.getContactsFromExtensionCount &&
      HexosparkExtOrgCountDataQuery &&
      HexosparkExtOrgCountDataQuery.HexosparkAdmin &&
      HexosparkExtOrgCountDataQuery.HexosparkAdmin.getOrganizationsFromExtensionCount
    ) {
      const arr = [
        {
          field: "contact",
          count: +HexosparkExtContCountDataQuery.HexosparkAdmin.getContactsFromExtensionCount.count,
        },
        {
          field: "organization",
          count: +HexosparkExtOrgCountDataQuery.HexosparkAdmin.getOrganizationsFromExtensionCount.count,
        },
      ];
      setHexosparkExtContAndOrgCount(arr);
    }
  }, [HexosparkExtContCountDataQuery, HexosparkExtOrgCountDataQuery]);

  useEffect(() => {
    //! chart has render bug we need to empty array in case two queries are done
    if (HexosparkExtOrgCountDataQueryLoading || HexosparkExtContCountDataQueryLoading) {
      setHexosparkExtContAndOrgCount([]);
    }
  }, [HexosparkExtOrgCountDataQueryLoading, HexosparkExtContCountDataQueryLoading]);

  const usersRegChartDataQuery = useQuery(USERS_REGISTRATION_PIECHART, {
    variables: { from: usersChartFilter.fromDate, to: usersChartFilter.toDate },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const withPropertiesChartDataQuery = useQuery(USERS_WITH_PROPERTIES_PIECHART, {
    variables: {
      from: withPropertiesChatFilter.fromDate,
      to: withPropertiesChatFilter.toDate,
      hideHex: filterHexScans,
    },
    fetchPolicy: "network-only",
    // pollInterval: 60000,
    notifyOnNetworkStatusChange: true,
  });

  const countriesChartDataQuery = useQuery(USERS_BY_COUNTRY_PIECHART, {
    variables: {
      from: ctrChartFilter.fromDate,
      to: ctrChartFilter.toDate,
      hideHex: filterHexScans,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  // const pricingChartDataQuery = useQuery(PRICING_PIECHART, {
  //   variables: {
  //     hidePropertiesFromHex: filterHexScans,
  //     from: pricingChartFilter.fromDate,
  //     to: pricingChartFilter.toDate,
  //   },
  //   fetchPolicy: "network-only",
  //   // pollInterval: 60000,
  //   notifyOnNetworkStatusChange: true,
  // });

  const watchPricingChartDataQuery = useQuery(WATCH_PRICING_PIECHART, {
    variables: {
      from: watchPricingChartFilter.fromDate,
      to: watchPricingChartFilter.toDate,
      hidePropertiesFromHex: filterHexScans,
    },
    fetchPolicy: "network-only",
    // pollInterval: 60000,
    notifyOnNetworkStatusChange: true,
  });

  // const upgradesChartDataQuery = useQuery(UPGRADED_PROPERTIES_PIECHART, {
  //   variables: {
  //     hideHex: filterHexScans,
  //     from: upgradesChartFilter.fromDate,
  //     to: upgradesChartFilter.toDate,
  //   },
  //   fetchPolicy: "network-only",
  //   notifyOnNetworkStatusChange: true,
  // });

  // const scanLogsChartDataBySourceQuery = useQuery(SCANREQUESTLOG_PIECHART, {
  //   variables: {
  //     groupedField: "source",
  //     hideHexScans: filterHexScans,
  //     showAll: true,
  //     from: sourceChartFilter.fromDate,
  //     to: sourceChartFilter.toDate,
  //   },
  //   fetchPolicy: "network-only",
  //   // pollInterval: 60000,
  //   notifyOnNetworkStatusChange: true,
  // });

  const scanLogsChartDataByToolsQuery = useQuery(SCANREQUESTLOG_PIECHART, {
    variables: {
      groupedField: "scanTool",
      hideHexScans: filterHexScans,
      showAll: showExtScans,
      from: toolsChartFilter.fromDate,
      to: toolsChartFilter.toDate,
    },
    fetchPolicy: "network-only",
    // pollInterval: 60000,
    notifyOnNetworkStatusChange: true,
  });

  // const tasksChartDataQuery = useQuery(TASKS_PIECHART, {
  //   variables: {
  //     hideHex: filterHexScans,
  //     from: tasksChartFilter.fromDate,
  //     to: tasksChartFilter.toDate,
  //   },
  //   fetchPolicy: "network-only",
  //   // pollInterval: 60000,
  //   notifyOnNetworkStatusChange: true,
  // });

  const notificationsChartDataQuery = useQuery(NOTIFICATIONS_PIECHART, {
    variables: {
      from: notificationsChartFiler.fromDate,
      to: notificationsChartFiler.toDate,
      hideHex: filterHexScans,
    },
    fetchPolicy: "network-only",
    // pollInterval: 60000,
    notifyOnNetworkStatusChange: true,
  });

  // const channelsChartDataQuery = useQuery(CHANNELS_PIECHART, {
  //   variables: {
  //     hideHex: filterHexScans,
  //     from: channelsChartFiler.fromDate,
  //     to: channelsChartFiler.toDate,
  //   },
  //   fetchPolicy: "network-only",
  //   notifyOnNetworkStatusChange: true,
  // });

  const wpToolsChartDataQuery = useQuery(WP_TOOLS_PIECHART, {
    variables: {
      from: wpToolsChartFilter.fromDate,
      to: wpToolsChartFilter.toDate,
      hideHex: filterHexScans,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const wpNotificationsChartDataQuery = useQuery(WATCH_NOTIFICATIONS_PIECHART, {
    variables: {
      from: wpNotificationsChartFilter.fromDate,
      to: wpNotificationsChartFilter.toDate,
      hideHex: filterHexScans,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const hexomaticNotificationsChartDataQuery = useQuery(HEXOMATIC_NOTIFICATIONS_PIECHART, {
    variables: {
      from: hexomaticNotificationsChartFilter.fromDate,
      to: hexomaticNotificationsChartFilter.toDate,
      hideHex: filterHexScans,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  // newly added

  const trafficUsageChartDataQuery = useQuery(GET_PIECHART_TRAFFIC_AND_CREDITS, {
    variables: {
      settings: {
        from: trafficUsageChartFilter.fromDate,
        to: trafficUsageChartFilter.toDate,
        hideHex: filterHexScans,
      },
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  // const hexomaticTasksChartDataQuery = useQuery(HEXOMATIC_TASKS_CHART, {
  //   variables: {
  //     from: hexomaticTasksChartFilter.fromDate,
  //     to: hexomaticTasksChartFilter.toDate,
  //     hideHex: filterHexScans,
  //   },
  //   fetchPolicy: "network-only",
  //   notifyOnNetworkStatusChange: true,
  // });

  // const hexomaticTasksUsersChartDataQuery = useQuery(HEXOMATIC_TASKS_USERS_CHART, {
  //   variables: {
  //     from: tasksUsersChartFilter.fromDate,
  //     to: tasksUsersChartFilter.toDate,
  //   },
  //   fetchPolicy: "network-only",
  //   notifyOnNetworkStatusChange: true,
  // });

  const shorthCutClikChart = useQuery(SHORTHCUT_CHART, {
    variables: {
      from: shorthCutClicksFilter.fromDate,
      to: shorthCutClicksFilter.toDate,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  const creditsUsageChartDataQuery = useQuery(GET_PIECHART_TRAFFIC_AND_CREDITS, {
    variables: {
      settings: {
        from: creditsUsageChartFilter.fromDate,
        to: creditsUsageChartFilter.toDate,
        hideHex: filterHexScans,
      },
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  // const automationsUsageChartDataQuery = useQuery(GET_PIECHART_AUTOMATIONS, {
  //   variables: {
  //     from: hexomaticAutomationsUsageChartFilter.fromDate,
  //     to: hexomaticAutomationsUsageChartFilter.toDate,
  //   },
  //   fetchPolicy: "network-only",
  //   notifyOnNetworkStatusChange: true,
  // });

  const HackathonSitesChartDataQuery = useQuery(GET_HACKATHON_SITES_CHART, {
    variables: {
      from: hackathonSitesChartFilter.fromDate,
      to: hackathonSitesChartFilter.toDate,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const ExtensionChartDataQuery = useQuery(GET_FREE_TOOLS_AND_EXTENSION_CHART, {
    variables: {
      from: extensionChartFilter.fromDate,
      to: extensionChartFilter.toDate,
      hideHex: filterHexScans,
      requestSource: "extension",
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const FreeToolsChartDataQuery = useQuery(GET_FREE_TOOLS_AND_EXTENSION_CHART, {
    variables: {
      from: freeToolsChartFilter.fromDate,
      to: freeToolsChartFilter.toDate,
      hideHex: filterHexScans,
      requestSource: "free_tools",
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const FreeToolsStatusChartDataQuery = useQuery(GET_FREE_TOOLS_AND_EXTENSION_STATUS_CHART, {
    variables: {
      from: freeToolsStatusChartFilter.fromDate,
      to: freeToolsStatusChartFilter.toDate,
      hideHex: filterHexScans,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  const workflowAnalitycsChartDataQuery = useQuery(WORKFLOW_ANALYTICS, {
    variables: {
      from: workflowAnalitycsFilter.fromDate,
      to: workflowAnalitycsFilter.toDate,
      hideHex: filterHexScans,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const registrationsFromInviteChartDataQuery = useQuery(GET_REGISTRATION_FROM_INVITE, {
    variables: {
      from: registrationFromInviteChartFilter.fromDate,
      to: registrationFromInviteChartFilter.toDate,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: hexowatchTraffic,
    loading: hexowatchTrafficLoading,
    refetch: refetchHexowatchTraffic,
  } = useQuery(GET_HEXOWATCH_PIECHART_TRAFFIC_AND_CREDITS, {
    variables: {
      from: hexowatchtrafficUsageChartFilter.fromDate,
      to: hexowatchtrafficUsageChartFilter.toDate,
      hideHex: filterHexScans,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: hexowatchScanTool,
    loading: hexowatchScanToolLoading,
    refetch: refetchHexowatchScanTool,
  } = useQuery(GET_HEXOWATCH_SCAN_TOOL_PIECHART, {
    variables: {
      from: hexowatchScanToolChartFilter.fromDate,
      to: hexowatchScanToolChartFilter.toDate,
      hideHex: filterHexScans,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: hexowatchPremiumCredit,
    loading: hexowatchPremiumCreditLoading,
    refetch: refetchHexowatchPremiumCredit,
  } = useQuery(GET_HEXOWATCH_PREMIUM_CREDIT_PIECHART, {
    variables: {
      from: hexowatchScanToolChartFilter.fromDate,
      to: hexowatchScanToolChartFilter.toDate,
      hideHex: filterHexScans,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (
      hexowatchPremiumCredit &&
      hexowatchPremiumCredit.Watch &&
      hexowatchPremiumCredit.Watch.watchPremiumCreditPieChart &&
      hexowatchScanTool &&
      hexowatchScanTool.Watch &&
      hexowatchScanTool.Watch.getWatchToolScanPieChart
    ) {
      const arr = [
        ...hexowatchScanTool.Watch.getWatchToolScanPieChart,
        {
          field: "premium_credits",
          count: +hexowatchPremiumCredit.Watch.watchPremiumCreditPieChart.premium_credits.toFixed(2),
        },
      ];

      setHexowatchScanToolAndCredit(arr);
    }
  }, [hexowatchPremiumCredit, hexowatchScanTool]);

  useEffect(() => {
    //! chart has render bug we need to empty array in case two queries are done
    if (hexowatchPremiumCreditLoading || hexowatchScanToolLoading) {
      setHexowatchScanToolAndCredit([]);
    }
  }, [hexowatchPremiumCreditLoading, hexowatchScanToolLoading]);

  const cardOptions = (
    <Card.Options>
      <Form.Checkbox
        label="Show All"
        checked={showExtScans}
        onChange={() => setShowExtScans(!showExtScans)}
      />
    </Card.Options>
  );

  const createPieChartArray = (obj: any): any[] => {
    const result = [];
    Object.keys(obj).forEach((el) => {
      if (el === "residential_geo" || el === "residential" || el === "data_center") {
        result.push({
          field: `mb ${el.split("_").join(" ")}`,
          count: +(obj[el] / 1024 / 1024).toFixed(2),
        });
      }
      if (el === "premium_credit" || el === "credit") {
        result.push({
          field: `${el.split("_").join(" ")}`,
          count: +obj[el].toFixed(1),
        });
      }
    });

    return result;
  };
  useEffect(() => {
    if (
      !trafficUsageChartDataQuery.loading &&
      trafficUsageChartDataQuery.data &&
      trafficUsageChartDataQuery.data.HexomaticAdmin &&
      trafficUsageChartDataQuery.data.HexomaticAdmin.pieChartTrafficAndCredits &&
      trafficUsageChartDataQuery.data.HexomaticAdmin.pieChartTrafficAndCredits.pie_chart_data === null
    ) {
      setTrafficUsageChartFilter(filterButtons[5]);
    }
  }, [trafficUsageChartDataQuery]);

  useEffect(() => {
    if (
      !creditsUsageChartDataQuery.loading &&
      creditsUsageChartDataQuery.data &&
      creditsUsageChartDataQuery.data.HexomaticAdmin &&
      creditsUsageChartDataQuery.data.HexomaticAdmin.pieChartTrafficAndCredits &&
      creditsUsageChartDataQuery.data.HexomaticAdmin.pieChartTrafficAndCredits.pie_chart_data === null
    ) {
      setCreditsUsageChartFilter(filterButtons[5]);
    }
  }, [creditsUsageChartDataQuery]);

  const getTotalCountyCount = useMemo(() => {
    if (
      countriesChartDataQuery.data &&
      countriesChartDataQuery.data.User &&
      countriesChartDataQuery.data.User.countriesPiechart
    ) {
      return countriesChartDataQuery.data.User.countriesPiechart.filter(
        (obj: { field: string; count: number }) => !obj.field.startsWith("$")
      ).length;
    }
  }, [countriesChartDataQuery]);

  return (
    <Grid.Row>
      <Grid.Col md={12}>
        <Grid.Row>
          <Grid.Col md={12}>
            <Grid.Row>
              <div className="col-12 col-md-6">
                {!usersRegChartDataQuery.loading &&
                usersRegChartDataQuery.data &&
                usersRegChartDataQuery.data.User &&
                usersRegChartDataQuery.data.User.registrationPiechart ? (
                  <PiechartCard
                    title="Registered users"
                    data={
                      usersRegChartDataQuery.data &&
                      usersRegChartDataQuery.data.User &&
                      usersRegChartDataQuery.data.User.registrationPiechart
                    }
                    filterBtnClick={(obj) => setUsersChartFilter(obj)}
                    filterObj={usersChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, usersChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setUsersChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, usersChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setUsersChartFilter(currentDateObj);
                    }}
                    colors={{
                      Active: "#64BA15",
                      Other: "#E74C3C",
                    }}
                    reload={true}
                    reloadData={usersRegChartDataQuery.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>
              <div className="col-12 col-md-6">
                {!usersTypesChartDataQuery.loading &&
                usersTypesChartDataQuery.data &&
                usersTypesChartDataQuery.data.User &&
                usersTypesChartDataQuery.data.User.typesPiechart ? (
                  <PiechartCard
                    title="Registered users by type"
                    data={
                      usersTypesChartDataQuery.data &&
                      usersTypesChartDataQuery.data.User &&
                      usersTypesChartDataQuery.data.User.typesPiechart &&
                      ["hexometer", "hexowatch", "hexomatic", "hexospark", 'hexofy'].map((typeName) => {
                        const type = usersTypesChartDataQuery.data.User.typesPiechart.find(
                          (el) => el.field === typeName
                        );
                        if (type) return type;
                        return {
                          field: typeName,
                          count: 0,
                        };
                      })
                    }
                    filterBtnClick={(obj) => setUsersTypesChartFilter(obj)}
                    filterObj={usersTypesChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, usersTypesChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setUsersTypesChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, usersTypesChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setUsersTypesChartFilter(currentDateObj);
                    }}
                    colors={{
                      hexometer: "#99CC33",
                      hexowatch: "#31B7FB",
                      hexomatic: "#A801FF",
                      hexospark: "#FF4C25",
                      hexofy: "#fac000",
                    }}
                    reload={true}
                    reloadData={usersTypesChartDataQuery.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>
              <div className="col-12 col-md-6">
                {!countriesChartDataQuery.loading &&
                countriesChartDataQuery.data &&
                countriesChartDataQuery.data.User &&
                countriesChartDataQuery.data.User.countriesPiechart ? (
                  <PiechartCard
                    limit={34}
                    showDataLenght
                    title={`Registered users by country - ${getTotalCountyCount}`}
                    data={
                      countriesChartDataQuery.data &&
                      countriesChartDataQuery.data.User &&
                      countriesChartDataQuery.data.User.countriesPiechart
                    }
                    filterBtnClick={(obj) => setCtrChartFilter(obj)}
                    filterObj={ctrChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, ctrChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setCtrChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, ctrChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setCtrChartFilter(currentDateObj);
                    }}
                    reload={true}
                    reloadData={countriesChartDataQuery.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>
              <div className="col-12 col-md-6">
                {!withPropertiesChartDataQuery.loading &&
                withPropertiesChartDataQuery.data &&
                withPropertiesChartDataQuery.data.User &&
                withPropertiesChartDataQuery.data.User.propertiesPiechart ? (
                  <PiechartCard
                    title="Hexometer users"
                    data={
                      withPropertiesChartDataQuery.data &&
                      withPropertiesChartDataQuery.data.User &&
                      withPropertiesChartDataQuery.data.User.propertiesPiechart
                    }
                    filterBtnClick={(obj) => setWithPropertiesChatFilter(obj)}
                    filterObj={withPropertiesChatFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, withPropertiesChatFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setWithPropertiesChatFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, withPropertiesChatFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setWithPropertiesChatFilter(currentDateObj);
                    }}
                    colors={{
                      paid: "#59BD9C",
                      "only free": "#F2864F",
                    }}
                    reload={true}
                    reloadData={withPropertiesChartDataQuery.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>
              <div className="col-12 col-md-6">
                {!watchPricingChartDataQuery.loading &&
                watchPricingChartDataQuery.data &&
                watchPricingChartDataQuery.data.UserWatchSettings &&
                watchPricingChartDataQuery.data.UserWatchSettings.watchPricingPackagePiechart ? (
                  <PiechartCard
                    title="Hexowatch users"
                    data={
                      watchPricingChartDataQuery.data &&
                      watchPricingChartDataQuery.data.UserWatchSettings &&
                      watchPricingChartDataQuery.data.UserWatchSettings.watchPricingPackagePiechart &&
                      watchPricingPiechart.map((packName) => {
                        const pack =
                          watchPricingChartDataQuery.data.UserWatchSettings.watchPricingPackagePiechart.find(
                            (el) => el.field === packName
                          );
                        if (pack) return pack;
                        return {
                          field: packName,
                          count: 0,
                        };
                      })
                    }
                    filterBtnClick={(obj) => setWatchPricingChartFilter(obj)}
                    filterObj={watchPricingChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, watchPricingChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setWatchPricingChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, watchPricingChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setWatchPricingChartFilter(currentDateObj);
                    }}
                    colors={{
                      "FREE-MONTHLY": "blue",
                      "FREE-ANNUAL": "lightblue",
                      "STANDARD-MONTHLY": "green",
                      "STANDARD-ANNUAL": "lightgreen",
                      "STANDARD-LTD": "red",
                      "PRO-MONTHLY": "salmon",
                      "PRO-ANNUAL": "purple",
                      "PRO-LTD": "violet",
                      "BUSINESS_SCANS_10000-MONTHLY": "brown",
                      "BUSINESS_SCANS_10000-ANNUAL": "chocolate",
                      "BUSINESS_SCANS_10000-LTD": "black",
                      "BUSINESS_SCANS_25000-MONTHLY": "grey",
                      "BUSINESS_SCANS_25000-ANNUAL": "yellow",
                      "BUSINESS_SCANS_25000-LTD": "orange",
                    }}
                    reload={true}
                    reloadData={watchPricingChartDataQuery.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>
              <div className="col-12 col-md-6">
                {!HexomaticPricingChartDataQueryLoading &&
                HexomaticPricingChartDataQuery &&
                HexomaticPricingChartDataQuery.HexomaticUser &&
                HexomaticPricingChartDataQuery.HexomaticUser.getHexomaticPricingPiechart ? (
                  <PiechartCard
                    title="Hexomatic users"
                    data={
                      HexomaticPricingChartDataQuery &&
                      HexomaticPricingChartDataQuery.HexomaticUser &&
                      HexomaticPricingChartDataQuery.HexomaticUser.getHexomaticPricingPiechart &&
                      HEXOMATIC_CREDITS_PACKAGE.map((packName) => {
                        const pack =
                          HexomaticPricingChartDataQuery.HexomaticUser.getHexomaticPricingPiechart.find(
                            (el) => el.field === packName
                          );
                        if (pack) return pack;
                        return {
                          field: packName,
                          count: 0,
                        };
                      })
                    }
                    filterBtnClick={(obj) => setHexomaticPricingChartFilter(obj)}
                    filterObj={hexomaticPricingChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, hexomaticPricingChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setHexomaticPricingChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, hexomaticPricingChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setHexomaticPricingChartFilter(currentDateObj);
                    }}
                    colors={{
                      FREE: "blue",
                      SILVER: "gray",
                      BRONZE: "#c77b30",
                      GOLD: "#d7a52d",
                    }}
                    reload={true}
                    reloadData={refetchHexomaticPricingChartData}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>
              <div className="col-12 col-md-6">
                {!HexosparkPricingChartDataQueryLoading &&
                HexosparkPricingChartDataQuery &&
                HexosparkPricingChartDataQuery.HexosparkAdmin &&
                HexosparkPricingChartDataQuery.HexosparkAdmin.getHsUserPricingPackagePieChart &&
                HexosparkPricingChartDataQuery.HexosparkAdmin.getHsUserPricingPackagePieChart.result ? (
                  <PiechartCard
                    title="Hexospark users"
                    data={
                      HexosparkPricingChartDataQuery &&
                      HexosparkPricingChartDataQuery.HexosparkAdmin &&
                      HexosparkPricingChartDataQuery.HexosparkAdmin.getHsUserPricingPackagePieChart &&
                      HexosparkPricingChartDataQuery.HexosparkAdmin.getHsUserPricingPackagePieChart.result &&
                      HEXOSPARK_CREDITS_PACKAGE.map((packName) => {
                        const pack =
                          HexosparkPricingChartDataQuery.HexosparkAdmin.getHsUserPricingPackagePieChart.result.find(
                            (el) => el.field === packName
                          );
                        if (pack) return pack;
                        return {
                          field: packName,
                          count: 0,
                        };
                      })
                    }
                    filterBtnClick={(obj) => setHexosparkPricingChartFilter(obj)}
                    filterObj={hexosparkPricingChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, hexosparkPricingChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setHexosparkPricingChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, hexosparkPricingChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setHexosparkPricingChartFilter(currentDateObj);
                    }}
                    colors={{
                      FREE: "blue",
                      PAID: "#d7a52d",
                    }}
                    reload={true}
                    reloadData={refetchHexosparkPricingChartData}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>

              <div className="col-12 col-md-6">
                {!HexosparkStorageDataQueryLoading &&
                HexosparkStorageDataQuery &&
                HexosparkStorageDataQuery.HexosparkAdmin &&
                HexosparkStorageDataQuery.HexosparkAdmin.getStorageUsagePieChart ? (
                  <PiechartWithoutDates
                    title="Hexospark storage usage"
                    showSelect={{
                      label: "Database location",
                      value: hexosparkStorageDBValue,
                      options: ["US", "EU"],
                      handleSelect: setHexosparkStorageDBValue,
                    }}
                    data={[
                      {
                        field: "mb storage",
                        count: +(
                          HexosparkStorageDataQuery.HexosparkAdmin.getStorageUsagePieChart.storage /
                          1024 /
                          1024
                        ).toFixed(2),
                      },
                    ]}
                    colors={{
                      "mb storage": "blue",
                    }}
                    reload={true}
                    reloadData={refetchHexosparkStorageChartData}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>

              <div className="col-12 col-md-6">
                {!HexosparkCampaignStatusesDataQueryLoading &&
                HexosparkCampaignStatusesDataQuery &&
                HexosparkCampaignStatusesDataQuery.HexosparkAdmin &&
                HexosparkCampaignStatusesDataQuery.HexosparkAdmin.getCampaignStatusesPieChart &&
                HexosparkCampaignStatusesDataQuery.HexosparkAdmin.getCampaignStatusesPieChart.result ? (
                  <PiechartCard
                    title="Hexospark campaign statuses"
                    showSelect={{
                      label: "Database location",
                      value: hexosparkCampaignDBValue,
                      options: ["US", "EU"],
                      handleSelect: setHexosparkCampaignDBValue,
                    }}
                    data={
                      HexosparkCampaignStatusesDataQuery &&
                      HexosparkCampaignStatusesDataQuery.HexosparkAdmin &&
                      HexosparkCampaignStatusesDataQuery.HexosparkAdmin.getCampaignStatusesPieChart &&
                      HexosparkCampaignStatusesDataQuery.HexosparkAdmin.getCampaignStatusesPieChart.result &&
                      ["INITIAL", "PROGRESS", "PAUSED"].map((packName) => {
                        const pack =
                          HexosparkCampaignStatusesDataQuery.HexosparkAdmin.getCampaignStatusesPieChart.result.find(
                            (el) => el.field === packName
                          );
                        if (pack) return pack;
                        return {
                          field: packName,
                          count: 0,
                        };
                      })
                    }
                    filterBtnClick={(obj) => setHexosparkCampaignStatusesChartFilter(obj)}
                    filterObj={hexosparkCampaignStatusesChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, hexosparkCampaignStatusesChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setHexosparkCampaignStatusesChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, hexosparkCampaignStatusesChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setHexosparkCampaignStatusesChartFilter(currentDateObj);
                    }}
                    colors={{
                      INITIAL: "blue",
                      PROGRESS: "#0f6a7d",
                      PAUSED: "#16ac59",
                    }}
                    reload={true}
                    reloadData={refetchHexosparkCampaignStatusChartData}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>

              <div className="col-12 col-md-6">
                {!HexosparkEmailStatusesDataQueryLoading &&
                HexosparkEmailStatusesDataQuery &&
                HexosparkEmailStatusesDataQuery.HexosparkAdmin &&
                HexosparkEmailStatusesDataQuery.HexosparkAdmin.getEmailStatusesPieChart &&
                HexosparkEmailStatusesDataQuery.HexosparkAdmin.getEmailStatusesPieChart.result ? (
                  <PiechartCard
                    title="Hexospark email statuses"
                    showSelect={{
                      label: "Database location",
                      value: hexosparkEmailStatusesDBValue,
                      options: ["US", "EU"],
                      handleSelect: setHexosparkEmailStatusesDBValue,
                    }}
                    data={
                      HexosparkEmailStatusesDataQuery &&
                      HexosparkEmailStatusesDataQuery.HexosparkAdmin &&
                      HexosparkEmailStatusesDataQuery.HexosparkAdmin.getEmailStatusesPieChart &&
                      HexosparkEmailStatusesDataQuery.HexosparkAdmin.getEmailStatusesPieChart.result &&
                      ["EMAIL_TRIGGERED", "EMAIL_SENT", "EMAIL_REPLIED", "EMAIL_ERROR"].map((packName) => {
                        const pack =
                          HexosparkEmailStatusesDataQuery.HexosparkAdmin.getEmailStatusesPieChart.result.find(
                            (el) => el.field === packName
                          );
                        if (pack) return pack;
                        return {
                          field: packName,
                          count: 0,
                        };
                      })
                    }
                    filterBtnClick={(obj) => setHexosparkEmailStatusesChartFilter(obj)}
                    filterObj={hexosparkEmailStatusesChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, hexosparkEmailStatusesChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setHexosparkEmailStatusesChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, hexosparkEmailStatusesChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setHexosparkEmailStatusesChartFilter(currentDateObj);
                    }}
                    colors={{
                      EMAIL_TRIGGERED: "blue",
                      EMAIL_SENT: "#0f6a7d",
                      EMAIL_REPLIED: "#16ac59",
                      EMAIL_ERROR: "red",
                    }}
                    reload={true}
                    reloadData={refetchHexosparkEmailStatusesChartData}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>

              <div className="col-12 col-md-6">
                {!HexosparkExtOrgCountDataQueryLoading &&
                !HexosparkExtContCountDataQueryLoading &&
                hexosparkExtContAndOrgCount.length ? (
                  <PiechartCard
                    title="Hexospark extension contact and organization"
                    showSelect={{
                      label: "Database location",
                      value: hexosparkExtContCountDBValue,
                      options: ["US", "EU"],
                      handleSelect: setHexosparkExtContCountDBValue,
                    }}
                    data={["contact", "organization"].map((packName) => {
                      const pack = hexosparkExtContAndOrgCount.find((el) => el.field === packName);
                      if (pack) return pack;
                      return {
                        field: packName,
                        count: 0,
                      };
                    })}
                    filterBtnClick={(obj) => setHexosparkExtContAndOrgCountChartFilter(obj)}
                    filterObj={hexosparkExtContAndOrgCountChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, hexosparkExtContAndOrgCountChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setHexosparkExtContAndOrgCountChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, hexosparkExtContAndOrgCountChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setHexosparkExtContAndOrgCountChartFilter(currentDateObj);
                    }}
                    colors={{
                      contact: "blue",
                      organization: "#16ac59",
                    }}
                    reload={true}
                    reloadData={() => {
                      refetchHexosparkExtContCountChartData();
                      refetchHexosparkExtOrgCountChartData();
                    }}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>

              <div className="col-12 col-md-6">
                {!trafficUsageChartDataQuery.loading &&
                trafficUsageChartDataQuery.data &&
                trafficUsageChartDataQuery.data.HexomaticAdmin &&
                trafficUsageChartDataQuery.data.HexomaticAdmin.pieChartTrafficAndCredits &&
                trafficUsageChartDataQuery.data.HexomaticAdmin.pieChartTrafficAndCredits.pie_chart_data ? (
                  <PiechartCard
                    title="Hexomatic traffic usage"
                    data={
                      !trafficUsageChartDataQuery.loading &&
                      trafficUsageChartDataQuery.data &&
                      trafficUsageChartDataQuery.data.HexomaticAdmin &&
                      trafficUsageChartDataQuery.data.HexomaticAdmin.pieChartTrafficAndCredits &&
                      trafficUsageChartDataQuery.data.HexomaticAdmin.pieChartTrafficAndCredits
                        .pie_chart_data &&
                      ["mb data center", "mb residential geo", "mb residential"].map((typeName) => {
                        const type =
                          !trafficUsageChartDataQuery.loading &&
                          trafficUsageChartDataQuery.data &&
                          trafficUsageChartDataQuery.data.HexomaticAdmin &&
                          trafficUsageChartDataQuery.data.HexomaticAdmin.pieChartTrafficAndCredits &&
                          trafficUsageChartDataQuery.data.HexomaticAdmin.pieChartTrafficAndCredits
                            .pie_chart_data &&
                          createPieChartArray(
                            !trafficUsageChartDataQuery.loading &&
                              trafficUsageChartDataQuery.data &&
                              trafficUsageChartDataQuery.data.HexomaticAdmin &&
                              trafficUsageChartDataQuery.data.HexomaticAdmin.pieChartTrafficAndCredits &&
                              trafficUsageChartDataQuery.data.HexomaticAdmin.pieChartTrafficAndCredits
                                .pie_chart_data
                          ).find((el) => el.field === typeName);
                        if (type) return type;
                        return {
                          field: typeName,
                          count: 0,
                        };
                      })
                    }
                    filterBtnClick={(obj) => setTrafficUsageChartFilter(obj)}
                    filterObj={trafficUsageChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, trafficUsageChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setTrafficUsageChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, trafficUsageChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setTrafficUsageChartFilter(currentDateObj);
                    }}
                    colors={{
                      "mb data center": "#0f6a7d",
                      "mb residential": "#16ac59",
                      "mb residential geo": "#a087b2",
                    }}
                    reload={true}
                    reloadData={trafficUsageChartDataQuery.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>
              {/*Hexowatch traffic usage */}
              <div className="col-12 col-md-6">
                {!hexowatchTrafficLoading &&
                hexowatchTraffic &&
                hexowatchTraffic.Watch &&
                hexowatchTraffic.Watch.watchTrafficChart &&
                hexowatchTraffic.Watch.watchTrafficChart.watchTrafficChart ? (
                  <PiechartCard
                    title="Hexowatch traffic usage"
                    data={
                      !hexowatchTrafficLoading &&
                      hexowatchTraffic &&
                      hexowatchTraffic.Watch &&
                      hexowatchTraffic.Watch.watchTrafficChart &&
                      hexowatchTraffic.Watch.watchTrafficChart.watchTrafficChart &&
                      ["data_center", "residential_geo"].map((typeName) => {
                        return {
                          field: `mb ${typeName.replace("_", " ")}`,
                          count:
                            (
                              hexowatchTraffic.Watch.watchTrafficChart.watchTrafficChart[typeName] /
                              1024 /
                              1024
                            ).toFixed(2) || 0,
                        };
                      })
                    }
                    filterBtnClick={(obj) => setHexowatchTrafficUsageChartFilter(obj)}
                    filterObj={hexowatchtrafficUsageChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, hexowatchtrafficUsageChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setHexowatchTrafficUsageChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, hexowatchtrafficUsageChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setHexowatchTrafficUsageChartFilter(currentDateObj);
                    }}
                    colors={{
                      data_center: "#0f6a7d",
                      residential_geo: "#a087b2",
                    }}
                    reload={true}
                    reloadData={refetchHexowatchTraffic}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>
              {/*//////////////////////// */}
              {/* ////////////--------------CREDIT -------//////////// */}
              <div className="col-12 col-md-6">
                {!creditsUsageChartDataQuery.loading &&
                creditsUsageChartDataQuery.data &&
                creditsUsageChartDataQuery.data.HexomaticAdmin &&
                creditsUsageChartDataQuery.data.HexomaticAdmin.pieChartTrafficAndCredits &&
                creditsUsageChartDataQuery.data.HexomaticAdmin.pieChartTrafficAndCredits.pie_chart_data ? (
                  <PiechartCard
                    title="Hexomatic credits usage"
                    data={
                      !creditsUsageChartDataQuery.loading &&
                      creditsUsageChartDataQuery.data &&
                      creditsUsageChartDataQuery.data.HexomaticAdmin &&
                      creditsUsageChartDataQuery.data.HexomaticAdmin.pieChartTrafficAndCredits &&
                      creditsUsageChartDataQuery.data.HexomaticAdmin.pieChartTrafficAndCredits
                        .pie_chart_data &&
                      ["credit", "premium credit"].map((typeName) => {
                        const type = createPieChartArray(
                          !creditsUsageChartDataQuery.loading &&
                            creditsUsageChartDataQuery.data &&
                            creditsUsageChartDataQuery.data.HexomaticAdmin &&
                            creditsUsageChartDataQuery.data.HexomaticAdmin.pieChartTrafficAndCredits &&
                            creditsUsageChartDataQuery.data.HexomaticAdmin.pieChartTrafficAndCredits
                              .pie_chart_data
                        ).find((el) => el.field === typeName);
                        if (type) return type;
                        return {
                          field: typeName,
                          count: 0,
                        };
                      })
                    }
                    filterBtnClick={(obj) => setCreditsUsageChartFilter(obj)}
                    filterObj={creditsUsageChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, creditsUsageChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setCreditsUsageChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, creditsUsageChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setCreditsUsageChartFilter(currentDateObj);
                    }}
                    colors={{
                      credit: "#e83e8c",
                      "premium credit": "#6054e7",
                    }}
                    reload={true}
                    reloadData={creditsUsageChartDataQuery.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>
              {/* <div style={{ border: "solid red" }} className="col-12 col-md-6">
                {!creditsUsageChartDataQuery.loading &&
                creditsUsageChartDataQuery.data &&
                creditsUsageChartDataQuery.data.HexomaticAdmin &&
                creditsUsageChartDataQuery.data.HexomaticAdmin
                  .pieChartTrafficAndCredits &&
                creditsUsageChartDataQuery.data.HexomaticAdmin
                  .pieChartTrafficAndCredits.pie_chart_data ? (
                  <PiechartCard
                    title="Hexomatic shared recipes"
                    data={
                      !creditsUsageChartDataQuery.loading &&
                      creditsUsageChartDataQuery.data &&
                      creditsUsageChartDataQuery.data.HexomaticAdmin &&
                      creditsUsageChartDataQuery.data.HexomaticAdmin
                        .pieChartTrafficAndCredits &&
                      creditsUsageChartDataQuery.data.HexomaticAdmin
                        .pieChartTrafficAndCredits.pie_chart_data &&
                      ["shares", "imports"].map((typeName) => {
                        const type = createPieChartArray(
                          !creditsUsageChartDataQuery.loading &&
                            creditsUsageChartDataQuery.data &&
                            creditsUsageChartDataQuery.data.HexomaticAdmin &&
                            creditsUsageChartDataQuery.data.HexomaticAdmin
                              .pieChartTrafficAndCredits &&
                            creditsUsageChartDataQuery.data.HexomaticAdmin
                              .pieChartTrafficAndCredits.pie_chart_data
                        ).find((el) => el.field === typeName);
                        if (type) return type;
                        return {
                          field: typeName,
                          count: 0,
                        };
                      })
                    }
                    filterBtnClick={(obj) => setCreditsUsageChartFilter(obj)}
                    filterObj={creditsUsageChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign(
                        {},
                        creditsUsageChartFilter
                      );
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setCreditsUsageChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign(
                        {},
                        creditsUsageChartFilter
                      );
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setCreditsUsageChartFilter(currentDateObj);
                    }}
                    colors={{
                      shares: "#e83e8c",
                      imports: "#6054e7",
                    }}
                    reload={true}
                    reloadData={creditsUsageChartDataQuery.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div> */}
              {/* <div style={{ border: "solid red" }} className="col-12 col-md-6">
                {!creditsUsageChartDataQuery.loading &&
                creditsUsageChartDataQuery.data &&
                creditsUsageChartDataQuery.data.HexomaticAdmin &&
                creditsUsageChartDataQuery.data.HexomaticAdmin
                  .pieChartTrafficAndCredits &&
                creditsUsageChartDataQuery.data.HexomaticAdmin
                  .pieChartTrafficAndCredits.pie_chart_data ? (
                  <PiechartCard
                    title="Hexomatic shared workflows"
                    data={
                      !creditsUsageChartDataQuery.loading &&
                      creditsUsageChartDataQuery.data &&
                      creditsUsageChartDataQuery.data.HexomaticAdmin &&
                      creditsUsageChartDataQuery.data.HexomaticAdmin
                        .pieChartTrafficAndCredits &&
                      creditsUsageChartDataQuery.data.HexomaticAdmin
                        .pieChartTrafficAndCredits.pie_chart_data &&
                      ["shares", "imports"].map((typeName) => {
                        const type = createPieChartArray(
                          !creditsUsageChartDataQuery.loading &&
                            creditsUsageChartDataQuery.data &&
                            creditsUsageChartDataQuery.data.HexomaticAdmin &&
                            creditsUsageChartDataQuery.data.HexomaticAdmin
                              .pieChartTrafficAndCredits &&
                            creditsUsageChartDataQuery.data.HexomaticAdmin
                              .pieChartTrafficAndCredits.pie_chart_data
                        ).find((el) => el.field === typeName);
                        if (type) return type;
                        return {
                          field: typeName,
                          count: 0,
                        };
                      })
                    }
                    filterBtnClick={(obj) => setCreditsUsageChartFilter(obj)}
                    filterObj={creditsUsageChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign(
                        {},
                        creditsUsageChartFilter
                      );
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setCreditsUsageChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign(
                        {},
                        creditsUsageChartFilter
                      );
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setCreditsUsageChartFilter(currentDateObj);
                    }}
                    colors={{
                      shares: "#e83e8c",
                      imports: "#6054e7",
                    }}
                    reload={true}
                    reloadData={creditsUsageChartDataQuery.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div> */}
              <div className="col-12 col-md-6">
                {!scanLogsChartDataByToolsQuery.loading &&
                scanLogsChartDataByToolsQuery.data &&
                scanLogsChartDataByToolsQuery.data.ScanRequestLog &&
                scanLogsChartDataByToolsQuery.data.ScanRequestLog.scanRequestLogPieChart ? (
                  <PiechartCard
                    cardOptions={cardOptions}
                    title="Hexometer scan requests by tools"
                    data={
                      scanLogsChartDataByToolsQuery.data &&
                      scanLogsChartDataByToolsQuery.data.ScanRequestLog &&
                      scanLogsChartDataByToolsQuery.data.ScanRequestLog.scanRequestLogPieChart &&
                      tools.map((toolName) => {
                        const tool =
                          scanLogsChartDataByToolsQuery.data.ScanRequestLog.scanRequestLogPieChart.find(
                            (el) => el.field === toolName
                          );
                        if (tool) return tool;
                        return {
                          field: toolName,
                          count: 0,
                        };
                      })
                    }
                    filterBtnClick={(obj) => setToolsChartFilter(obj)}
                    filterObj={toolsChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, toolsChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setToolsChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, toolsChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setToolsChartFilter(currentDateObj);
                    }}
                    reload={true}
                    reloadData={scanLogsChartDataByToolsQuery.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>
              <div className="col-12 col-md-6">
                {!hexowatchPremiumCreditLoading &&
                !hexowatchScanToolLoading &&
                hexowatchPremiumCredit &&
                hexowatchPremiumCredit.Watch &&
                hexowatchPremiumCredit.Watch.watchPremiumCreditPieChart &&
                hexowatchScanTool &&
                hexowatchScanTool.Watch &&
                hexowatchScanTool.Watch.getWatchToolScanPieChart &&
                hexowatchScanToolAndCredit.length ? (
                  <PiechartCard
                    title="Hexowatch checks usage"
                    data={hexowatchScanToolAndCredit.map((toolObj) => {
                      const tool = hexowatchScanToolAndCredit.find((el) => el.field === toolObj.field);
                      if (tool) {
                        return {
                          field:
                            tool.field === "premium_credits" ? "Premium credits" : hexowatchTools[tool.field],
                          count: tool.count,
                        };
                      }
                      return {
                        field:
                          toolObj.field === "premium_credits"
                            ? "Premium credits"
                            : hexowatchTools[toolObj.field],
                        count: 0,
                      };
                    })}
                    filterBtnClick={(obj) => setHexowatchScanToolChartFilter(obj)}
                    filterObj={hexowatchScanToolChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, hexowatchScanToolChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setHexowatchScanToolChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, hexowatchScanToolChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setHexowatchScanToolChartFilter(currentDateObj);
                    }}
                    reload={true}
                    reloadData={() => {
                      refetchHexowatchScanTool();
                      refetchHexowatchPremiumCredit();
                    }}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>
              <div className="col-12 col-md-6">
                {!notificationsChartDataQuery.loading &&
                notificationsChartDataQuery.data &&
                notificationsChartDataQuery.data.Notification &&
                notificationsChartDataQuery.data.Notification.notificationsPieChart ? (
                  <PiechartCard
                    title="Hexometer notifications by channels"
                    data={
                      notificationsChartDataQuery.data &&
                      notificationsChartDataQuery.data.Notification &&
                      notificationsChartDataQuery.data.Notification.notificationsPieChart &&
                      ["email", "slack", "telegram", "sms", "trello"].map((chnl) => {
                        const channel =
                          notificationsChartDataQuery.data.Notification.notificationsPieChart.find(
                            (el) => el.field === chnl
                          );
                        if (channel) return channel;
                        return {
                          field: chnl,
                          count: 0,
                        };
                      })
                    }
                    filterBtnClick={(obj) => setNotificationsChartFiler(obj)}
                    filterObj={notificationsChartFiler}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, notificationsChartFiler);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setNotificationsChartFiler(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, notificationsChartFiler);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setNotificationsChartFiler(currentDateObj);
                    }}
                    reload={true}
                    reloadData={notificationsChartDataQuery.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>
              <div className="col-12 col-md-6">
                {!wpToolsChartDataQuery.loading &&
                wpToolsChartDataQuery.data &&
                wpToolsChartDataQuery.data.Watch &&
                wpToolsChartDataQuery.data.Watch.toolsPiechart ? (
                  <PiechartCard
                    title="Hexowatch properties by tools"
                    data={
                      wpToolsChartDataQuery.data &&
                      wpToolsChartDataQuery.data.Watch &&
                      wpToolsChartDataQuery.data.Watch.toolsPiechart &&
                      wpToolsChartDataQuery.data &&
                      wpToolsChartDataQuery.data.Watch &&
                      wpToolsChartDataQuery.data.Watch.toolsPiechart.map((tool) => {
                        const tools = wpToolsChartDataQuery.data.Watch.toolsPiechart.find(
                          (el) => el.field === tool.field
                        );
                        if (tools) return tools;
                        return {
                          field: tool,
                          count: 0,
                        };
                      })
                    }
                    filterBtnClick={(obj) => setWpToolsChartFilter(obj)}
                    filterObj={wpToolsChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, wpToolsChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setWpToolsChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, wpToolsChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setWpToolsChartFilter(currentDateObj);
                    }}
                    reload={true}
                    reloadData={wpToolsChartDataQuery.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>
              <div className="col-12 col-md-6">
                {!wpNotificationsChartDataQuery.loading &&
                wpNotificationsChartDataQuery.data &&
                wpNotificationsChartDataQuery.data.WatchNotification &&
                wpNotificationsChartDataQuery.data.WatchNotification.watchNotificationsPieChart ? (
                  <PiechartCard
                    title="Hexowatch notifications by channels"
                    data={
                      wpNotificationsChartDataQuery.data &&
                      wpNotificationsChartDataQuery.data.WatchNotification &&
                      wpNotificationsChartDataQuery.data.WatchNotification.watchNotificationsPieChart &&
                      ["email", "slack", "telegram"].map((chnl) => {
                        const channel =
                          wpNotificationsChartDataQuery.data.WatchNotification.watchNotificationsPieChart.find(
                            (el) => el.field === chnl
                          );
                        if (channel) return channel;
                        return {
                          field: chnl,
                          count: 0,
                        };
                      })
                    }
                    filterBtnClick={(obj) => setWpNotificationsChartFilter(obj)}
                    filterObj={wpNotificationsChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, wpNotificationsChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setWpNotificationsChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, wpNotificationsChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setWpNotificationsChartFilter(currentDateObj);
                    }}
                    reload={true}
                    reloadData={wpNotificationsChartDataQuery.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>
              <div className="col-12 col-md-6">
                {!hexomaticNotificationsChartDataQuery.loading &&
                hexomaticNotificationsChartDataQuery.data &&
                hexomaticNotificationsChartDataQuery.data.HexomaticNotification &&
                hexomaticNotificationsChartDataQuery.data.HexomaticNotification
                  .hexomaticNotificationsPieChart ? (
                  <PiechartCard
                    title="Hexomatic notifications by types"
                    data={
                      hexomaticNotificationsChartDataQuery.data &&
                      hexomaticNotificationsChartDataQuery.data.HexomaticNotification &&
                      hexomaticNotificationsChartDataQuery.data.HexomaticNotification
                        .hexomaticNotificationsPieChart &&
                      hexomaticNotificationsChartDataQuery.data &&
                      hexomaticNotificationsChartDataQuery.data.HexomaticNotification &&
                      hexomaticNotificationsChartDataQuery.data.HexomaticNotification.hexomaticNotificationsPieChart.map(
                        (type) => {
                          const types =
                            hexomaticNotificationsChartDataQuery.data.HexomaticNotification.hexomaticNotificationsPieChart.find(
                              (el) => el.field === type.field
                            );
                          if (types) return types;
                          return {
                            field: type,
                            count: 0,
                          };
                        }
                      )
                    }
                    filterBtnClick={(obj) => setHexomaticNotificationsChartFilter(obj)}
                    filterObj={hexomaticNotificationsChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, hexomaticNotificationsChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setHexomaticNotificationsChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, hexomaticNotificationsChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setHexomaticNotificationsChartFilter(currentDateObj);
                    }}
                    reload={true}
                    reloadData={hexomaticNotificationsChartDataQuery.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>
              {/* <div className="col-12 col-md-6">
                {!!(
                  !hexomaticTasksChartDataQuery.loading &&
                  hexomaticTasksChartDataQuery.data &&
                  hexomaticTasksChartDataQuery.data.HexomaticHumanValidationsTasks &&
                  hexomaticTasksChartDataQuery.data.HexomaticHumanValidationsTasks
                    .adminGetHumanValidationTaskStatusChart
                ) ? (
                  <PiechartCard
                    title="Hexomatic human powered data collection"
                    data={
                      hexomaticTasksChartDataQuery.data &&
                      hexomaticTasksChartDataQuery.data.HexomaticHumanValidationsTasks &&
                      hexomaticTasksChartDataQuery.data.HexomaticHumanValidationsTasks
                        .adminGetHumanValidationTaskStatusChart &&
                      ["New", "Completed", "Archived", "In progress", "Paused"].map((chnl) => {
                        const channel =
                          hexomaticTasksChartDataQuery.data.HexomaticHumanValidationsTasks.adminGetHumanValidationTaskStatusChart.find(
                            (el) => chnl.toLocaleLowerCase().includes(el.field)
                          );
                        if (channel) return { field: chnl, count: channel.count };
                        return {
                          field: chnl,
                          count: 0,
                        };
                      })
                    }
                    filterBtnClick={(obj) => setHexomaticTasksChartFilter(obj)}
                    filterObj={hexomaticTasksChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, hexomaticTasksChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setHexomaticTasksChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, hexomaticTasksChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setHexomaticTasksChartFilter(currentDateObj);
                    }}
                    reload={true}
                    reloadData={hexomaticTasksChartDataQuery.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>
              <div className="col-12 col-md-6">
                {!hexomaticTasksUsersChartDataQuery.loading &&
                hexomaticTasksUsersChartDataQuery.data &&
                hexomaticTasksUsersChartDataQuery.data.HexomaticHumanValidationsTasks &&
                hexomaticTasksUsersChartDataQuery.data.HexomaticHumanValidationsTasks
                  .adminGetHumanValidationTaskUsersChart ? (
                  <PiechartCard
                    title="Hexomatic human powered data collection users"
                    data={
                      hexomaticTasksUsersChartDataQuery.data &&
                      hexomaticTasksUsersChartDataQuery.data.HexomaticHumanValidationsTasks &&
                      hexomaticTasksUsersChartDataQuery.data.HexomaticHumanValidationsTasks
                        .adminGetHumanValidationTaskUsersChart && [
                        {
                          field: "Creators",
                          count:
                            (hexomaticTasksUsersChartDataQuery.data &&
                              hexomaticTasksUsersChartDataQuery.data.HexomaticHumanValidationsTasks &&
                              hexomaticTasksUsersChartDataQuery.data.HexomaticHumanValidationsTasks
                                .adminGetHumanValidationTaskUsersChart.creators) ||
                            0,
                        },
                        {
                          field: "Performers",
                          count:
                            (hexomaticTasksUsersChartDataQuery.data &&
                              hexomaticTasksUsersChartDataQuery.data.HexomaticHumanValidationsTasks &&
                              hexomaticTasksUsersChartDataQuery.data.HexomaticHumanValidationsTasks
                                .adminGetHumanValidationTaskUsersChart.prformers) ||
                            0,
                        },
                      ]
                    }
                    filterBtnClick={(obj) => setTasksUsersChartFilter(obj)}
                    filterObj={tasksUsersChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, tasksUsersChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setTasksUsersChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, tasksUsersChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setTasksUsersChartFilter(currentDateObj);
                    }}
                    reload={true}
                    reloadData={hexomaticTasksUsersChartDataQuery.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div> */}
              <div className="col-12 col-md-6">
                {!shorthCutClikChart.loading &&
                shorthCutClikChart.data &&
                shorthCutClikChart.data.ShortcutLog &&
                shorthCutClikChart.data.ShortcutLog.getShortcutLogPieChart ? (
                  <PiechartCard
                    title="Google Chrome shortcut clicks"
                    data={
                      shorthCutClikChart.data &&
                      shorthCutClikChart.data.ShortcutLog &&
                      shorthCutClikChart.data.ShortcutLog.getShortcutLogPieChart &&
                      shorthCutClikChart.data &&
                      shorthCutClikChart.data.ShortcutLog &&
                      shorthCutClikChart.data.ShortcutLog.getShortcutLogPieChart.length &&
                      shorthCutClikChart.data &&
                      shorthCutClikChart.data.ShortcutLog &&
                      shorthCutClikChart.data.ShortcutLog.getShortcutLogPieChart.map((item) => {
                        return {
                          field: item.field,
                          count: item.count,
                        };
                      })
                    }
                    colors={{
                      hexomatic: "#825fec",
                      hexowatch: "#0ab8f7",
                      hexometer: "#16ac59",
                    }}
                    filterBtnClick={(obj) => setShorthCutClicksFilter(obj)}
                    filterObj={shorthCutClicksFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, shorthCutClicksFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setShorthCutClicksFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, shorthCutClicksFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setShorthCutClicksFilter(currentDateObj);
                    }}
                    reload={true}
                    reloadData={shorthCutClikChart.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>
              <div className="col-12 col-md-6">
                {!registrationsFromInviteChartDataQuery.loading &&
                registrationsFromInviteChartDataQuery.data &&
                registrationsFromInviteChartDataQuery.data.Referral &&
                registrationsFromInviteChartDataQuery.data.Referral.getReferralChart ? (
                  <PiechartCard
                    title="Referral link stats"
                    data={
                      registrationsFromInviteChartDataQuery.data &&
                      registrationsFromInviteChartDataQuery.data.Referral &&
                      registrationsFromInviteChartDataQuery.data.Referral.getReferralChart &&
                      ["click", "registration", "payment"].map((typeName) => {
                        const type =
                          registrationsFromInviteChartDataQuery.data.Referral.getReferralChart.find(
                            (el) => el.field === typeName
                          );
                        if (type) return type;
                        return {
                          field: typeName,
                          count: 0,
                        };
                      })
                    }
                    filterBtnClick={(obj) => setRegistrationFromInviteChartFilter(obj)}
                    filterObj={registrationFromInviteChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, registrationFromInviteChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setRegistrationFromInviteChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, registrationFromInviteChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setRegistrationFromInviteChartFilter(currentDateObj);
                    }}
                    colors={{
                      click: "#99CC33",
                      payment: "#31B7FB",
                      registration: "#A801FF",
                    }}
                    reload={true}
                    reloadData={registrationsFromInviteChartDataQuery.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>
              <div className="col-12 col-md-6">
                {!workflowAnalitycsChartDataQuery.loading &&
                workflowAnalitycsChartDataQuery.data &&
                workflowAnalitycsChartDataQuery.data.HexomaticWorkflow &&
                workflowAnalitycsChartDataQuery.data.HexomaticWorkflow.getWorkflowChart ? (
                  <PiechartCard
                    title="Hexomatic workflow analytics"
                    data={[
                      "progress",
                      "completed",
                      "DRAFT",
                      "error",
                      "PAUSED",
                      "deleted",
                      "PREMIUM_CREDIT_REACHED",
                      "CREDIT_REACHED",
                      "STORAGE_REACHED",
                      "CREDIT_STORAGE_REACHED",
                      "PREMIUM_CREDIT_STORAGE_REACHED",
                    ].map((chnl) => {
                      const channel =
                        workflowAnalitycsChartDataQuery.data.HexomaticWorkflow.getWorkflowChart.find(
                          (el) => el.field === chnl
                        );
                      if (channel)
                        return {
                          ...channel,
                          field: channel.field.toLocaleLowerCase(),
                        };
                      return {
                        field: chnl.toLocaleLowerCase(),
                        count: 0,
                      };
                    })}
                    filterBtnClick={(obj) => setWorkflowAnalitycsFilter(obj)}
                    filterObj={workflowAnalitycsFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, workflowAnalitycsFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setWorkflowAnalitycsFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, workflowAnalitycsFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setWorkflowAnalitycsFilter(currentDateObj);
                    }}
                    reload={true}
                    reloadData={workflowAnalitycsChartDataQuery.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>
              <div className="col-12 col-md-6">
                {!HackathonSitesChartDataQuery.loading &&
                HackathonSitesChartDataQuery.data &&
                HackathonSitesChartDataQuery.data.ExternalPages &&
                HackathonSitesChartDataQuery.data.ExternalPages.getExternalPagesChart ? (
                  <PiechartCard
                    title="Other products"
                    data={
                      HackathonSitesChartDataQuery.data.ExternalPages.getExternalPagesChart &&
                      HackathonSitesChartDataQuery.data.ExternalPages.getExternalPagesChart.map((chnl) => {
                        const channel =
                          HackathonSitesChartDataQuery.data.ExternalPages.getExternalPagesChart.find(
                            (el) => el.field === chnl.field
                          );
                        if (channel) return channel;
                        return {
                          field: chnl,
                          count: 0,
                        };
                      })
                    }
                    filterBtnClick={(obj) => setHackathonSitesChartFilter(obj)}
                    filterObj={hackathonSitesChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, hackathonSitesChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setHackathonSitesChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, hackathonSitesChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setHackathonSitesChartFilter(currentDateObj);
                    }}
                    reload={true}
                    reloadData={HackathonSitesChartDataQuery.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>
              <div className="col-12 col-md-6">
                {!FreeToolsStatusChartDataQuery.loading &&
                FreeToolsStatusChartDataQuery.data &&
                FreeToolsStatusChartDataQuery.data.FreeTools &&
                FreeToolsStatusChartDataQuery.data.FreeTools.getFreeToolsStatusChart ? (
                  <PiechartCard
                    title="Hexomatic free tools and extension statuses"
                    data={
                      FreeToolsStatusChartDataQuery.data.FreeTools.getFreeToolsStatusChart &&
                      FreeToolsStatusChartDataQuery.data.FreeTools.getFreeToolsStatusChart.map((chnl) => {
                        const channel =
                          FreeToolsStatusChartDataQuery.data.FreeTools.getFreeToolsStatusChart.find(
                            (el) => el.field === chnl.field
                          );
                        if (channel) return channel;
                        return {
                          field: chnl,
                          count: 0,
                        };
                      })
                    }
                    filterBtnClick={(obj) => setFreeToolsStatusChartFilter(obj)}
                    filterObj={freeToolsStatusChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, freeToolsStatusChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setFreeToolsStatusChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, freeToolsStatusChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setFreeToolsStatusChartFilter(currentDateObj);
                    }}
                    reload={true}
                    reloadData={FreeToolsStatusChartDataQuery.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>
              <div className="col-12 col-md-6">
                {!FreeToolsChartDataQuery.loading &&
                FreeToolsChartDataQuery.data &&
                FreeToolsChartDataQuery.data.FreeTools &&
                FreeToolsChartDataQuery.data.FreeTools.getFreeToolsChart ? (
                  <PiechartCard
                    title="Hexomatic free tools"
                    data={
                      FreeToolsChartDataQuery.data.FreeTools.getFreeToolsChart &&
                      FreeToolsChartDataQuery.data.FreeTools.getFreeToolsChart.map((chnl) => {
                        const channel = FreeToolsChartDataQuery.data.FreeTools.getFreeToolsChart.find(
                          (el) => el.field === chnl.field
                        );
                        if (channel) return channel;
                        return {
                          field: chnl,
                          count: 0,
                        };
                      })
                    }
                    filterBtnClick={(obj) => setFreeToolsChartFilter(obj)}
                    filterObj={freeToolsChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, freeToolsChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setFreeToolsChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, freeToolsChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setFreeToolsChartFilter(currentDateObj);
                    }}
                    reload={true}
                    reloadData={FreeToolsChartDataQuery.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>

              <div className="col-12 col-md-6">
                {!ExtensionChartDataQuery.loading &&
                ExtensionChartDataQuery.data &&
                ExtensionChartDataQuery.data.FreeTools &&
                ExtensionChartDataQuery.data.FreeTools.getFreeToolsChart ? (
                  <PiechartCard
                    title="Hexomatic extension"
                    data={
                      ExtensionChartDataQuery.data.FreeTools.getFreeToolsChart &&
                      ExtensionChartDataQuery.data.FreeTools.getFreeToolsChart.map((chnl) => {
                        const channel = ExtensionChartDataQuery.data.FreeTools.getFreeToolsChart.find(
                          (el) => el.field === chnl.field
                        );
                        if (channel) return channel;
                        return {
                          field: chnl,
                          count: 0,
                        };
                      })
                    }
                    filterBtnClick={(obj) => setExtensionChartFilter(obj)}
                    filterObj={extensionChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign({}, extensionChartFilter);
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setExtensionChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign({}, extensionChartFilter);
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setExtensionChartFilter(currentDateObj);
                    }}
                    reload={true}
                    reloadData={ExtensionChartDataQuery.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div>

              {/* <div className="col-12 col-md-12">
                {!automationsUsageChartDataQuery.loading &&
                automationsUsageChartDataQuery.data &&
                automationsUsageChartDataQuery.data.HexomaticAutomation &&
                automationsUsageChartDataQuery.data.HexomaticAutomation
                  .getAutomationsChart ? (
                  <PiechartCard
                    title="Hexomatic automations usage"
                    data={
                      automationsUsageChartDataQuery.data.HexomaticAutomation
                        .getAutomationsChart &&
                      automationsUsageChartDataQuery.data.HexomaticAutomation.getAutomationsChart.map(
                        (chnl) => {
                          const channel =
                            automationsUsageChartDataQuery.data.HexomaticAutomation.getAutomationsChart.find(
                              (el) => el.field === chnl.field
                            );
                          if (channel) return channel;
                          return {
                            field: chnl,
                            count: 0,
                          };
                        }
                      )
                    }
                    filterBtnClick={(obj) =>
                      setHexomaticAutomationsUsageChartFilter(obj)
                    }
                    filterObj={hexomaticAutomationsUsageChartFilter}
                    fromDateChange={(fromDate: string) => {
                      const currentDateObj = Object.assign(
                        {},
                        hexomaticAutomationsUsageChartFilter
                      );
                      currentDateObj.fromDate = fromDate;
                      currentDateObj.name = "Custom";
                      setHexomaticAutomationsUsageChartFilter(currentDateObj);
                    }}
                    toDateChange={(toDate: string) => {
                      const currentDateObj = Object.assign(
                        {},
                        hexomaticAutomationsUsageChartFilter
                      );
                      currentDateObj.toDate = toDate;
                      currentDateObj.name = "Custom";
                      setHexomaticAutomationsUsageChartFilter(currentDateObj);
                    }}
                    reload={true}
                    reloadData={automationsUsageChartDataQuery.refetch}
                  />
                ) : (
                  <Card>
                    <Loader className="position-static" />
                  </Card>
                )}
              </div> */}
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      </Grid.Col>
    </Grid.Row>
  );
};

export default DailyMetrics;
