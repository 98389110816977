export const tools: ReadonlyArray<string> = [
  "urlCheckerTool",
  "domainWhoisTool",
  "deviceScreenshots",
  "brokenLinksTool",
  "techStackTool",
  "w3cCheckTool",
  "sslExtractionTool",
  "assetsCheckerTool",
  "domainBlacklistTool",
  "ipExtractionTool",
  "webMetricsTool",
];

export const hexowatchTools: {
  sectionElement: string;
  keywordSection: string;
  techStackTool: string;
  sectionScreenTool: string;
  pingTool: string;
  htmlSectionTool: string;
  contentSectionTool: string;
  domainWhoisTool: string;
  automaticAITool: string;
  sitemapTool: string;
  httpRequestTool: string;
  backlinkTool: string;
  rssTool: string;
} = {
  sectionElement: "HTML element monitoring",
  keywordSection: "Keyword monitoring",
  techStackTool: "Technology monitoring",
  sectionScreenTool: "Visual monitoring",
  pingTool: "Availability monitoring",
  htmlSectionTool: "Source code monitoring",
  contentSectionTool: "Content monitoring",
  domainWhoisTool: "Domain WHOIS Monitoring",
  automaticAITool: "Automatic AI monitoring",
  sitemapTool: "Sitemap monitoring",
  httpRequestTool: "Api monitoring",
  backlinkTool: "Backlink monitoring",
  rssTool: "Rss monitoring",
};

export const products = ["hexometer", "hexowatch", "hexomatic"];
export const actions = ["click", "registration", "payment"];
export const sources = ["web", "extension", "fullpagescreenshot"];
export const allTools = [""];

// export const pricingPacks = [
//     'free-annual',
//     'free-month',
//     'agency-annual',
//     'agency-month',
//     'business-annual',
//     'business-month',
//     'premium_15000-annual',
//     'premium_15000-month',
//     'premium_25000-annual',
//     'premium_25000-month',
//     'premium_50000-annual',
//     'premium_50000-month',
//     'premium_100000-annual',
//     'premium_100000-month'
// ];

export const pricingPacks = [
  "STANDARD",
  "ADVANCED",
  "GURU",
  "AGENCY_STANDARD",
  "AGENCY_ADVANCED",
  "AGENCY_GURU",
];

export const watchPricingPiechart = [
  "FREE-MONTHLY",
  "FREE-ANNUAL",
  "STANDARD-MONTHLY",
  "STANDARD-ANNUAL",
  "STANDARD-LTD",
  "PRO-MONTHLY",
  "PRO-ANNUAL",
  "PRO-LTD",
  "BUSINESS_SCANS_10000-MONTHLY",
  "BUSINESS_SCANS_10000-ANNUAL",
  "BUSINESS_SCANS_10000-LTD",
  "BUSINESS_SCANS_25000-MONTHLY",
  "BUSINESS_SCANS_25000-ANNUAL",
  "BUSINESS_SCANS_25000-LTD",
  "BUSINESS_SCANS_50000-LTD",
  "BUSINESS_SCANS_75000-LTD",
  "BUSINESS_SCANS_100000-LTD",
  "BUSINESS_SCANS_125000-LTD",
  "BUSINESS_SCANS_150000-LTD",
];

export const watchPricingPacks = [
  "FREE",
  "STANDARD",
  "PRO",
  "BUSINESS_SCANS_10000",
  "BUSINESS_SCANS_25000",
  "BUSINESS_SCANS_50000",
  "BUSINESS_SCANS_75000",
  "BUSINESS_SCANS_100000",
  "BUSINESS_SCANS_125000",
  "BUSINESS_SCANS_150000",
  "ANY_PAID",
  "NOT_PAID",
  "SUBSCRIBERS",
];

export const watchPricingPacksList = [
  "FREE",
  "STANDARD",
  "PRO",
  "BUSINESS_SCANS_10000",
  "BUSINESS_SCANS_25000",
  "BUSINESS_SCANS_50000",
  "BUSINESS_SCANS_75000",
  "BUSINESS_SCANS_100000",
  "BUSINESS_SCANS_125000",
  "BUSINESS_SCANS_150000",
];
export const watchPricingPackIntervals = ["MONTHLY", "ANNUAL", "LTD"];

export const wpTools = [
  "Technology monitoring",
  "Keyword monitoring",
  "HTML element monitoring",
  "Visual monitoring",
  "Availability monitoring",
  "Source code monitoring",
  "Content monitoring",
  "Automatic AI monitoring",
  "Domain WHOIS monitoring",
  "Sitemap monitoring",
];

export const HEXOMETER_PRICING_PACKAGE_INTERVALS = ["MONTHLY", "ANNUAL", "LTD"];

export const HEXOSPARK_PRICING_PACKAGE_INTERVALS = ["MONTHLY", "ANNUAL", "LTD"];

export const HEXOFY_PRICING_PACKAGE_INTERVALS = ["MONTHLY", "ANNUAL", "LTD"];

export const HEXOFY_PRICING_PACKAGES = ["FREE", "STARTER", "UNLIMITED", "TEAM", "ANY_PAID", "NOT_PAID", "SUBSCRIBERS"];

export const HEXOSPARK_PRICING_PACKAGES = [
  "FREE",
  "ANY_PAID",
  "PAID",
  'SOLO',
  'TEAM',
  "NOT_PAID",
  "SUBSCRIBERS",
];

export const HEXOMETER_PRICING_PACKAGES = [
  "FREE",
  "STANDARD",
  "ADVANCED",
  "GURU",
  "AGENCY_STANDARD",
  "AGENCY_ADVANCED",
  "AGENCY_GURU",
  "ANY_PAID",
  "NOT_PAID",
  "SUBSCRIBERS",
];
export const HEXOMETER_PRICING_PACKAGES_LIST = [
  "FREE",
  "STANDARD",
  "ADVANCED",
  "GURU",
  "AGENCY_STANDARD",
  "AGENCY_ADVANCED",
  "AGENCY_GURU",
];
export const HEXOMATE_PRICING_PACKAGE_INTERVAL = ["MONTHLY", "ANNUAL", "LTD"];

export const HEXOMATIC_CREDITS_PACKAGE = ["FREE", "BRONZE", "SILVER", "GOLD"];

export const HEXOSPARK_CREDITS_PACKAGE = ["FREE", "PAID"];

export const HEXOMATIC_CREDITS_PACKAGE_INTERVAL = ["MONTHLY", "ANNUAL", "LTD"];

export const PREMIUM_CREDITS_INTERVAL = ["MONTHLY", "ANNUAL"];

export const HEXOMATE_PRICING_PACKAGE = [
  "FREE",
  "BRONZE",
  "SILVER",
  "GOLD",
  "ANY_PAID",
  "NOT_PAID",
  "SUBSCRIBERS",
];

export const otherTemplateNames = [
  { label: "Discover profile", value: 20 },
  { label: "Inspect content", value: 21 },
  { label: "Pull contacts", value: 22 },
  { label: "Fullpagescreencapture", value: 23 },
];

export const hexomaticTemplateNames = [
  { label: "Workflow status", value: 2 },
  {
    label: "Scraping recipe share",
    value: 3,
  },
  { label: "Workflow share", value: 4 },
];

export const hexowatchTemplateNames = [
  { label: "Daily digest", value: 5 },
  {
    label: "Event detected by a tool",
    value: [1, 6, 7, 9, 16, 18, 19],
  },
];

export const hexometerTemplateNames = [
  { label: "Property share", value: 15 },
  { label: "Monitoring cycle summary", value: 12 },
  {
    label: "Alert emails (broken links, meta, IP blacklist, JS errors, page speed, security headers, uptime)",
    value: [8, 24, 10, 11, 13, 14, 17],
  },
];

export const fullTemplatesList = [
  { label: "Discover profile", value: 20 },
  { label: "Pull contacts", value: 22 },
  { label: "Inspect content", value: 21 },
  { label: "Fullpagescreencapture", value: 23 },
  { label: "Workflow status", value: 2 },
  {
    label: "Scraping recipe share",
    value: 3,
  },
  { label: "Workflow share", value: 4 },
  { label: "Daily digest", value: 5 },
  {
    label: "Event detected by a tool",
    value: [1, 6, 7, 9, 16, 18, 19],
  },
  { label: "Property share", value: 15 },
  { label: "Monitoring cycle summary", value: 12 },
  {
    label: "Alert emails (broken links, meta, IP blacklist, JS errors, page speed, security headers, uptime)",
    value: [8, 24, 10, 11, 13, 14, 17],
  },
];
